import BLOCKCHAIN from "@/constants/blockchain";
import { BytesValue, TokenTransfer } from "@multiversx/sdk-core";
import elrondApiHelper from "@/helpers/elrondApi";
import gamesProxyContract, { GAMES_PROXY_FUNCTIONS } from "@/contracts/gamesProxy";
import elrondHelper from "@/helpers/elrond";
import { REFERRAL_GETTERS } from "@/store/referral";
import { TransactionOnNetwork } from "@multiversx/sdk-network-providers/out";
import { TransactionDecoder } from "@multiversx/sdk-transaction-decoder/lib/src/transaction.decoder";
import hideAndSeekContract, { HIDE_AND_SEEK_FUNCTIONS } from "@/contracts/hideAndSeek";

export const RPS_GETTERS = {
  RECENT_GAMES: "rpsRecentGames",
};

export const RPS_MUTATIONS = {
  RECENT_GAMES: "rpsRecentGames",
};

export const RPS_ACTIONS = {
  PLAY: "rpsPlay",
  GET_RECENT_GAMES: "rpsGetRecentGames",
};

interface RECENT_GAME {
  index: number;
  hash: string;
  address: string;
  tokenTransfer: TokenTransfer;
  status: number;
  goNuts: boolean;
}

interface IRpsStore {
  state: () => {
    recentGames: RECENT_GAME[];
  };

  [index: string]: any;
}

export const rpsStore: IRpsStore = {
  state: () => ({
    recentGames: [],
  }),
  getters: {
    [RPS_GETTERS.RECENT_GAMES](state) {
      return state.recentGames;
    },
  },
  mutations: {
    [RPS_MUTATIONS.RECENT_GAMES](state, recentGames) {
      state.recentGames = recentGames;
    },
  },
  actions: {
    async [RPS_ACTIONS.PLAY]({ getters }, { accountElrond, collection, selectedCard, token, amount, goNutsPricePerEgld }) {
      return await gamesProxyContract.rps(
        accountElrond,
        collection,
        selectedCard,
        token,
        amount,
        BLOCKCHAIN.TOKENS[token].decimals,
        getters[REFERRAL_GETTERS.REFERER],
        goNutsPricePerEgld
          ? goNutsPricePerEgld
            .multipliedBy(0.98) // 2% slippage
            .multipliedBy(amount)
            .decimalPlaces(0)
          : null
      );
    },
    async [RPS_ACTIONS.GET_RECENT_GAMES]({ commit, getters }) {
      if (getters[RPS_GETTERS.RECENT_GAMES].length) {
        return;
      }

      const response = await elrondApiHelper.getTransactionsToProxy(GAMES_PROXY_FUNCTIONS.RPS);

      await hideAndSeekContract.getContractAbi();

      const decoder = new TransactionDecoder();

      let index = 0;

      const recentGames: RECENT_GAME[] = response
        .map((transaction: TransactionOnNetwork) => {
          const results = elrondHelper.parseCustomResults(
            transaction,
            hideAndSeekContract.contract.getEndpoint(HIDE_AND_SEEK_FUNCTIONS.PLAY_RPS)
          );

          if (!results) {
            return null;
          }

          const { firstValue } = results;

          const metadata = decoder.getTransactionMetadata({
            sender: transaction.sender.bech32(),
            receiver: transaction.receiver.bech32(),
            data: transaction.data.toString("base64"),
            value: transaction.value.toString(),
          });

          const tokenTransfer = !metadata.transfers
            ? TokenTransfer.egldFromBigInteger(transaction.value)
            : TokenTransfer.fungibleFromBigInteger(
                metadata.transfers[0].properties.identifier,
                metadata.transfers[0].value.toString(),
                BLOCKCHAIN.TOKENS[metadata.transfers[0].properties.identifier]?.decimals || 18
              );

          index++;

          const result = (firstValue as BytesValue).toString();

          return {
            index,
            hash: transaction.hash,
            address: transaction.sender.bech32(),
            tokenTransfer,
            status: "draw" === result ? 1 : "win" === result ? 2 : 0,
            goNuts: !!metadata.functionArgs?.[3],
          };
        })
        .filter((value) => null !== value);

      commit(RPS_MUTATIONS.RECENT_GAMES, recentGames);
    },
  },
};
