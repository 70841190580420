import BLOCKCHAIN from "@/constants/blockchain";
import ELROND from "@/constants/elrond";
import GENERAL from "@/constants/general";
import {
  ApiNetworkProvider,
  FungibleTokenOfAccountOnNetwork,
  TransactionOnNetwork,
} from "@multiversx/sdk-network-providers";
import { Account } from "@multiversx/sdk-core";
import { Address } from "@multiversx/sdk-core/out";

export interface NFT_POST {
  name: string;
  uri: string;
  nonce: number;
  rarity?: number;
  customRarity?: string;
}

class ElrondApiHelper {
  apiProvider: ApiNetworkProvider;

  constructor() {
    this.apiProvider = new ApiNetworkProvider(ELROND.API, { timeout: 30000 });
  }

  async getAddressNftsCount(address: string, collection: string): Promise<number> {
    return await this.apiProvider.doGetGeneric(`accounts/${address}/nfts/count?collections=${collection}`);
  }

  async getAddressNfts(
    address: string,
    collection: string,
    page: number,
    count: number,
    perPage: number = GENERAL.PER_PAGE
  ): Promise<NFT_POST[]> {
    if (count <= page * perPage) {
      return [];
    }

    // Reverse nfts call since for address nfts are ordered by default in asc not desc order
    // const lastIndex = count - page * GENERAL.PER_PAGE;
    // const firstIndex = lastIndex > GENERAL.PER_PAGE ? lastIndex - GENERAL.PER_PAGE : 0;
    const firstIndex = page * perPage;
    const lastIndex = firstIndex + perPage;
    const size = Math.min(lastIndex - firstIndex, perPage);

    const response = await this.apiProvider.doGetGeneric(
      `accounts/${address}/nfts?collections=${collection}&from=${firstIndex}&size=${size}`
    );

    return response.reverse().map(ElrondApiHelper.mapNft);
  }

  async getAddressStakedPositions<T>(
    address: string,
    collection: string,
    page: number,
    count: number,
    currentBlock: number,
    mapStakedPosition: (nft: any, currentBlock: number) => Promise<T>
  ): Promise<T[]> {
    if (count <= page * GENERAL.PER_PAGE) {
      return [];
    }

    // Reverse nfts call since for address nfts are ordered by default in asc not desc order
    const lastIndex = count - page * GENERAL.PER_PAGE;
    const firstIndex = lastIndex > GENERAL.PER_PAGE ? lastIndex - GENERAL.PER_PAGE : 0;
    const size = Math.min(lastIndex - firstIndex, GENERAL.PER_PAGE);

    const response = (
      await this.apiProvider.doGetGeneric(
        `accounts/${address}/nfts?collections=${collection}&from=${firstIndex}&size=${size}`
      )
    ).reverse();

    return await Promise.all(response.map(async (nft) => await mapStakedPosition(nft, currentBlock)));
  }

  async getAccount(addressStr: string): Promise<Account> {
    const address = new Address(addressStr);
    const account = new Account(address);

    this.apiProvider.getNetworkGeneralStatistics();

    const accountOnNetwork = await this.apiProvider.getAccount(address);

    account.update(accountOnNetwork);

    return account;
  }

  async getAccountTokenBalance(address: string, token: string): Promise<FungibleTokenOfAccountOnNetwork> {
    return await this.apiProvider.getFungibleTokenOfAccount(new Address(address), token);
  }

  async getTransactionsToProxy(
    fct: string,
    sender: string = null,
    size: number = GENERAL.PER_PAGE,
    after: number = null,
    before: number = null
  ): Promise<TransactionOnNetwork[]> {
    const response = await this.apiProvider.doGetGeneric(
      `transactions?receiver=${BLOCKCHAIN.CONTRACTS.GAMES_PROXY}&function=${fct}&size=${size}&status=success&withScResults=true&withLogs=false` +
        (sender ? `&sender=${sender}` : "") +
        (after ? `&after=${after}` : "") +
        (before ? `&before=${before}` : "")
    );

    return response.map((transaction) => {
      return {
        function: transaction.function,
        ...TransactionOnNetwork.fromApiHttpResponse(transaction.txHash, transaction),
      };
    });
  }

  async getTransactionsCountToProxy(after: number, before: number = null, sender: string = null): Promise<number> {
    const response = await this.apiProvider.doGetGeneric(
      `transactions/count?receiver=${BLOCKCHAIN.CONTRACTS.GAMES_PROXY}&status=success&after=${after}` +
        (before ? `&before=${before}` : "") +
        (sender ? `&sender=${sender}` : "")
    );

    return Number.parseInt(response);
  }

  async getAccountTransactions(address: string, size: number = GENERAL.PER_PAGE): Promise<TransactionOnNetwork[]> {
    const response = await this.apiProvider.doGetGeneric(
      `accounts/${address}/transactions?size=${size}&status=success&withScResults=true&withLogs=false`
    );

    return response.map((transaction) => {
      return {
        function: transaction.function,
        ...TransactionOnNetwork.fromApiHttpResponse(transaction.txHash, transaction),
      };
    });
  }

  async getNfts(nonces: number[], token: string = BLOCKCHAIN.NFT_TOKEN_IDENTIFIER): Promise<NFT_POST[]> {
    if (!nonces.length) {
      return [];
    }

    const newNonces = nonces.map((nonce) => {
      let newNonce: string = nonce.toString(16);

      if (newNonce.length % 2) {
        newNonce = "0" + newNonce;
      }

      return `${token}-${newNonce}`;
    });

    const chunkedIdentifiers = newNonces.reduce(
      (prev: string[][], currentValue: string) => {
        const prevChunk = prev[prev.length - 1];

        if (prevChunk.length < 25) {
          prevChunk.push(currentValue);

          return prev;
        }

        prev.push([currentValue]);

        return prev;
      },
      [[]]
    );

    return await Promise.all(
      chunkedIdentifiers.map(async (identifiers) => {
        return await this.apiProvider.doGetGeneric(`nfts?identifiers=${identifiers}&withOwner=true`);
      })
    )
      .then((response) => response.flat())
      .then((nfts) => nfts.map(ElrondApiHelper.mapNft));
  }

  async getNft(nonce: number, token: string = BLOCKCHAIN.NFT_TOKEN_IDENTIFIER): Promise<NFT_POST> {
    let newNonce: string = nonce.toString(16);
    if (newNonce.length % 2) {
      newNonce = "0" + newNonce;
    }
    newNonce = `${token}-${newNonce}`;

    return await this.apiProvider
      .doGetGeneric(`nfts/${newNonce}`)
      .then((response) => ElrondApiHelper.mapNft(response));
  }

  async getEgldTokenPrice() {
    try {
      const token = await this.apiProvider.doGetGeneric(`tokens/${ BLOCKCHAIN.TOKEN_IDENTIFIER_WEGLD }`);

      return Number.parseFloat(token.price);
    } catch (e) {
      return 0.0;
    }
  }

  private static mapNft(nft: any): NFT_POST {
    return {
      name: nft.name,
      uri: nft.url,
      nonce: nft.nonce,
      rarity: Math.round(nft?.metadata?.rarity?.rarityScore * 100) / 100,
      customRarity: nft.metadata.attributes?.[0]?.value,
    };
  }
}

const elrondApiHelper = new ElrondApiHelper();

export default elrondApiHelper;
