const GENERAL = {
  DEFAULT_LANGUAGE: 'en',
  PER_PAGE: 12,

  STATUS_CODE_ACCESS_DENIED: 403,
  STATUS_CODE_INTERNAL_SERVER_ERROR: 500,

  THEMES: {
    DARK: 'dark',
    LIGHT: 'light',
  },

  STORAGE: {
    THEME: 'theme',
  },

  SITE_URL: window.location.origin,

  REFERER_PARAM: 'referer',
};

export default GENERAL;
