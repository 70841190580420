import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row vh-100 overflow-y-auto" }
const _hoisted_2 = {
  id: "sidebar",
  class: "vh-100 col-md-3 px-0 col-xl-2 col-4k-1 bg-gray d-flex sticky-top d-none d-lg-flex"
}
const _hoisted_3 = { class: "col d-flex flex-column p-0 pb-5 pb-lg-0 mb-5 mb-lg-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMobile = _resolveComponent("SidebarMobile")!
  const _component_SidebarCommon = _resolveComponent("SidebarCommon")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageLoader = _resolveComponent("PageLoader")!
  const _component_MessageModal = _resolveComponent("MessageModal")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_TransactionsToast = _resolveComponent("TransactionsToast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SidebarMobile),
    _createElementVNode("div", {
      class: _normalizeClass(["container-fluid overflow-hidden app", $setup.theme])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SidebarCommon)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass($setup.route?.meta?.noMargin ? 'px-2' : 'mt-4 p-2 pb-5')
          }, [
            (false === $setup.loading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_router_view),
                  _createVNode(_component_router_view, { name: "modal" })
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_PageLoader, {
              loading: true === $setup.loading
            }, null, 8, ["loading"]),
            _createVNode(_component_MessageModal),
            _createVNode(_component_ConfirmModal),
            _createVNode(_component_TransactionsToast)
          ], 2)
        ])
      ])
    ], 2)
  ], 64))
}