import {
  Account,
  Address,
  ArgSerializer,
  EndpointDefinition,
  ITransactionOnNetwork,
  ResultsParser,
  Transaction,
  TransactionWatcher,
} from "@multiversx/sdk-core";
import ELROND from "@/constants/elrond";
import elrondApiHelper from "@/helpers/elrondApi";
import { NetworkConfig, ProxyNetworkProvider, TransactionOnNetwork } from "@multiversx/sdk-network-providers";
import { ExtensionProvider } from "@multiversx/sdk-extension-provider";
import { WalletConnectV2Provider } from "@multiversx/sdk-wallet-connect-provider";
import { HWProvider } from "@multiversx/sdk-hw-provider";
import { TransactionDecoder } from "@multiversx/sdk-transaction-decoder/lib/src/transaction.decoder";
import { WalletProvider } from "@multiversx/sdk-web-wallet-provider";
import { Interaction } from "@multiversx/sdk-core/out";
import ENV from "@/constants/env";
import storageHelper from "@/helpers/storage";
import { WebviewProvider } from "@multiversx/sdk-webview-provider/out";

export interface DecodedLoginTokenType {
  blockHash: string;
  extraInfo?: { timestamp: number };
  origin: string;
  ttl: number;
}

export interface DecodedNativeAuthTokenType extends DecodedLoginTokenType {
  address: string;
  body: string;
  signature: string;
}

export enum WALLET_LOGIN_TYPE {
  WEB_WALLET = "web_wallet",
  XALIAS = "xalias",
}

class ElrondHelper {
  provider: ExtensionProvider | WalletConnectV2Provider | HWProvider | WalletProvider | WebviewProvider;
  proxy: ProxyNetworkProvider;
  cachedProxy: ProxyNetworkProvider;
  networkConfig: NetworkConfig;
  transactionCache: {
    [hash: string]: TransactionOnNetwork;
  };

  constructor() {
    this.transactionCache = {};

    this.proxy = new ProxyNetworkProvider(ELROND.GATEWAY, { timeout: 30000 });
    this.cachedProxy = new ProxyNetworkProvider(ENV.MICROSERVICE_URL, { timeout: 30000 });
  }

  async setNetworkConfig() {
    this.networkConfig = await this.cachedProxy.getNetworkConfig();
  }

  async initializeMaiar(onClientLogin, onClientLogout) {
    await this.setNetworkConfig();

    this.provider = new WalletConnectV2Provider(
      {
        onClientLogin,
        onClientLogout,
        onClientEvent: () => {},
      },
      this.networkConfig.ChainID,
      ELROND.WALLET_CONNECT_WS,
      ELROND.WALLET_CONNECT_PROJECT_ID
    );

    return await this.provider.init();
  }

  async initializeExtension(initialAddress) {
    if (!initialAddress) {
      this.provider = ExtensionProvider.getInstance();
    } else {
      this.provider = ExtensionProvider.getInstance();

      this.provider.setAddress(initialAddress);
    }

    await this.setNetworkConfig();

    return await this.provider.init();
  }

  async initializeLedger() {
    this.provider = new HWProvider();

    await this.setNetworkConfig();

    return await this.provider.init();
  }

  async initializeWebWallet(type: WALLET_LOGIN_TYPE) {
    this.provider = new WalletProvider(
      type === WALLET_LOGIN_TYPE.WEB_WALLET ? ELROND.WALLET_PROVIDER : ELROND.XALIAS_PROVIDER
    );

    await this.setNetworkConfig();
  }

  async initializeWebView(account: DecodedNativeAuthTokenType, accessToken: string) {
    this.provider = WebviewProvider.getInstance();

    this.provider.setAccount({
      address: account.address,
      signature: account.signature,
      accessToken,
    });

    await this.setNetworkConfig();

    return await this.provider.init();
  }

  async login(redirect = "", token = "", addressIndex = null, isReload = false) {
    if (this.provider instanceof HWProvider) {
      if (!isReload) {
        const { address } = await this.provider.login({
          addressIndex,
        });

        return address;
      }

      await this.provider.setAddressIndex(addressIndex);

      return "";
    }

    if (this.provider instanceof WalletConnectV2Provider) {
      const { uri, approval } = await this.provider.connect();

      const params: any = { approval };

      if (token) {
        params.token = token;
      }

      this.provider
        .login(params)
        .then((value) => console.log("successfully connected", value))
        .catch((e) => console.log("error while connecting...", e));

      return uri;
    }

    if (this.provider instanceof ExtensionProvider) {
      const { address } = await this.provider.login({
        callbackUrl: window.location.origin + encodeURIComponent(redirect),
        token,
      });

      return address;
    }

    return await this.provider.login({
      callbackUrl: window.location.origin + encodeURIComponent(redirect),
      token,
    });
  }

  async getAccount(address): Promise<Account> {
    return await elrondApiHelper.getAccount(address);
  }

  async logout(): Promise<void> {
    if (!this.provider) {
      return;
    }

    if (this.provider instanceof WalletProvider && storageHelper.getWalletLoginType() === WALLET_LOGIN_TYPE.XALIAS) {
      return;
    }

    if (!(this.provider instanceof WalletProvider) && !this.provider.isInitialized()) {
      return;
    }

    await this.provider.logout();
  }

  async getCurrentBlock(shard: number, force: boolean = false): Promise<number> {
    let result;

    if (force) {
      result = await this.proxy.doGetGeneric(`network/status/${shard}`);
    } else {
      result = await this.cachedProxy.doGetGeneric(`network/status/${shard}`);
    }

    return result.status.erd_nonce;
  }

  calculateMasks(numOfShards: number) {
    const n = Math.ceil(Math.log2(numOfShards));
    const mask1 = (1 << n) - 1;
    const mask2 = (1 << (n - 1)) - 1;
    return [mask1, mask2];
  }

  // TODO: Fetch total shards from api
  getAddressShard(addressStr: string, totalShards: number = 3): number {
    const address = Address.fromString(addressStr);
    const [maskHigh, maskLow] = this.calculateMasks(totalShards);
    const pubKey = address.pubkey();
    const lastByteOfPubKey = pubKey[31];

    if (this.isAddressOfMetachain(pubKey)) {
      return 4294967295;
    }

    let shard = lastByteOfPubKey & maskHigh;

    if (shard > totalShards - 1) {
      shard = lastByteOfPubKey & maskLow;
    }

    return shard;
  }

  isAddressOfMetachain(pubKey: Buffer): boolean {
    // prettier-ignore
    const metachainPrefix = Buffer.from([
      0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const pubKeyPrefix = pubKey.slice(0, metachainPrefix.length);

    if (pubKeyPrefix.equals(metachainPrefix)) {
      return true;
    }

    const zeroAddress = Buffer.alloc(32).fill(0);

    if (pubKey.equals(zeroAddress)) {
      return true;
    }

    return false;
  }

  async buildAndSendInteraction(interaction: Interaction, account: Account): Promise<Transaction | null> {
    interaction.withNonce(account.nonce).withSender(account.address).withChainID(elrondHelper.networkConfig.ChainID);

    const transaction = interaction.buildTransaction();

    return await this.sendTransaction(transaction, account);
  }

  async sendTransaction(transaction: Transaction, account: Account): Promise<Transaction | null> {
    const { default: store, BASE_MUTATIONS } = await import("@/store");

    store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, true);

    try {
      transaction.setNonce(account.nonce);
      transaction.setSender(account.address);
      transaction.setChainID(elrondHelper.networkConfig.ChainID);

      if (this.provider instanceof WalletProvider) {
        await this.provider.signTransaction(transaction, {
          callbackUrl: encodeURIComponent(window.location.href),
        });

        return transaction;
      }

      // TODO: Implement proper guardian support for Ledger and test that Guardian signing works properly
      // @ts-ignore
      transaction = await this.provider.signTransaction(transaction);
      await this.proxy.sendTransaction(transaction);

      account.incrementNonce();

      return transaction;
    } catch (e) {
      console.error(e);

      return null;
    } finally {
      store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, null);
    }
  }

  async sendTransactions(
    transactions: Transaction[],
    account: Account,
    areSigned = false
  ): Promise<Transaction[] | null> {
    const { default: store, BASE_MUTATIONS } = await import("@/store");

    store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, true);

    try {
      if (!areSigned) {
        for (const transaction of transactions) {
          transaction.setNonce(account.getNonceThenIncrement());
          transaction.setSender(account.address);
          transaction.setChainID(elrondHelper.networkConfig.ChainID);
        }

        if (this.provider instanceof WalletProvider) {
          // @ts-ignore
          await this.provider.signTransactions(transactions, {
            callbackUrl: encodeURIComponent(window.location.href),
          });

          return [];
        }

        // TODO: Implement proper guardian support for Ledger and test that Guardian signing works properly
        // @ts-ignore
        transactions = await this.provider.signTransactions(transactions);
      }

      let lastNonce;
      for (const transaction of transactions) {
        await this.proxy.sendTransaction(transaction);

        lastNonce = transaction.getNonce();
      }

      if (lastNonce) {
        account.update({ nonce: lastNonce, balance: account.balance });
        account.incrementNonce();
      }

      return transactions;
    } catch (e) {
      console.error(e);

      return null;
    } finally {
      store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, null);
    }
  }

  getTransactionStatus(
    transaction?: TransactionOnNetwork,
    allowPending: boolean = false,
    returnTransaction: boolean = false
  ): boolean | string {
    if (!transaction) {
      return false;
    }

    if (!transaction.contractResults) {
      return transaction.status.isSuccessful();
    }

    try {
      const resultsParser = new ResultsParser();
      const result = resultsParser.parseUntypedOutcome(transaction);

      if (
        (transaction.status.isSuccessful() || (allowPending && transaction.status.isPending())) &&
        result.returnCode.isSuccess()
      ) {
        return true;
      }

      if (result.returnMessage) {
        return result.returnMessage;
      }
    } catch (e) {
      console.error(e);

      // It can happen that the contract results are not yet available, and we don't need them if returnTransaction is false
      if (!returnTransaction) {
        return transaction.status.isSuccessful();
      }
    }

    return false;
  }

  async getPendingTransaction(
    contract: string | null,
    fct: string | null,
    transaction: Transaction,
    returnTransaction: boolean = false,
    ignoreResultItem: boolean = false
  ): Promise<boolean | string | null | ITransactionOnNetwork> {
    const { default: store, BASE_MUTATIONS } = await import("@/store");

    const hash: string = transaction.getHash().toString();

    store.commit(BASE_MUTATIONS.TRANSACTION_TOAST, { hash, status: null });

    // Wait 6 seconds so transaction arrives to blockchain first
    await new Promise((resolve) => setTimeout(resolve, 6_000));

    const transactionWatcher = new TransactionWatcher(elrondApiHelper.apiProvider, {
      timeoutMilliseconds: TransactionWatcher.DefaultTimeout * 2,
    });

    let transactionOnNetwork: any;

    if (returnTransaction && !ignoreResultItem) {
      transactionOnNetwork = await transactionWatcher.awaitOnCondition(transaction, (transactionOnNetwork) => {
        const resultItemWithReturnData = transactionOnNetwork.contractResults.items.find((item) =>
          item.data.startsWith("@00@")
        );

        if (
          !resultItemWithReturnData &&
          true !== this.getTransactionStatus(transactionOnNetwork as TransactionOnNetwork, true)
        ) {
          return true;
        }

        return (
          resultItemWithReturnData &&
          true === this.getTransactionStatus(transactionOnNetwork as TransactionOnNetwork, true, true)
        );
      });
    } else {
      transactionOnNetwork = await transactionWatcher.awaitCompleted(transaction);
    }

    if (!hash) {
      return null;
    }

    let newTransaction: TransactionOnNetwork = this.transactionCache?.[hash];

    if (!newTransaction) {
      newTransaction = transactionOnNetwork;

      if (!newTransaction) {
        return null;
      }

      this.transactionCache[hash] = newTransaction;
    }

    // Check if transaction is for the desired contract and function
    if (contract && fct) {
      const decoder = new TransactionDecoder();

      const metadata = decoder.getTransactionMetadata({
        sender: newTransaction.sender.bech32(),
        receiver: newTransaction.receiver.bech32(),
        data: newTransaction.data.toString("base64"),
        value: newTransaction.value.toString(),
      });

      if (metadata.receiver !== contract || fct !== metadata.functionName) {
        return null;
      }
    }

    const status = this.getTransactionStatus(newTransaction, returnTransaction && !ignoreResultItem, returnTransaction);

    store.commit(BASE_MUTATIONS.TRANSACTION_TOAST, { hash, status });

    if (true === status && returnTransaction) {
      return transactionOnNetwork;
    }

    return status;
  }

  parseCustomResults(transaction: ITransactionOnNetwork, endpoint: EndpointDefinition): any {
    const resultItemWithReturnData = transaction.contractResults.items.find((item) => item.data.startsWith("@00@"));

    if (!resultItemWithReturnData) {
      return null;
    }

    const serializer = new ArgSerializer();

    const values = serializer.stringToValues(resultItemWithReturnData.data.slice(4), endpoint.output);

    return {
      values: values,
      firstValue: values[0],
      secondValue: values[1],
      thirdValue: values[2],
      lastValue: values[values.length - 1],
    };
  }

  decodeLoginToken = (
    loginToken: string
  ): DecodedLoginTokenType | null => {
    const parts = loginToken.split('.');

    if (parts.length !== 4) {
      return null;
    }

    try {
      const [origin, blockHash, ttl, extraInfo] = parts;
      const parsedExtraInfo = JSON.parse(atob(extraInfo));
      const parsedOrigin = atob(origin);

      return {
        ttl: Number(ttl),
        extraInfo: parsedExtraInfo,
        origin: parsedOrigin,
        blockHash
      };
    } catch (e) {
      console.error(`Error trying to decode ${loginToken}:`, e);

      return null;
    }
  };

  decodeNativeAuthToken = (
    accessToken: string
  ): DecodedNativeAuthTokenType | null => {
    const parts = accessToken.split('.');

    if (parts.length !== 3) {
      console.error(
        'Invalid nativeAuthToken. You may be trying to decode a loginToken. Try using decodeLoginToken method instead'
      );
      return null;
    }

    try {
      const [address, body, signature] = parts;
      const parsedAddress = atob(address);
      const parsedBody = atob(body);
      const parsedInitToken = this.decodeLoginToken(parsedBody);

      if (!parsedInitToken) {
        return {
          address: parsedAddress,
          body: parsedBody,
          signature,
          blockHash: '',
          origin: '',
          ttl: 0
        };
      }

      const result = {
        ...parsedInitToken,
        address: parsedAddress,
        body: parsedBody,
        signature
      };

      // if empty object, delete extraInfo
      if (!parsedInitToken.extraInfo?.timestamp) {
        delete result.extraInfo;
      }

      return result;
    } catch (err) {
      return null;
    }
  };
}

const elrondHelper = new ElrondHelper();

export default elrondHelper;
