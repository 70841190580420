import { Account } from "@multiversx/sdk-core";
import tokenFlipContract, { Game } from "@/contracts/tokenFlip";
import ELROND from "@/constants/elrond";
import gamesProxyContract from "@/contracts/gamesProxy";
import BLOCKCHAIN from "@/constants/blockchain";
import storageHelper from "@/helpers/storage";
import { REFERRAL_GETTERS } from "@/store/referral";

export const BLACKJACK_GETTERS = {
  GAME: "blackjackGame",
};

export const BLACKJACK_MUTATIONS = {
  GAME: "blackjackGame",
  RESET: "blackjackReset",
};

export const BLACKJACK_ACTIONS = {
  GET_GAME: "blackjackGetGame",
  PLAY: "blackjackPlay",
};

interface IBlackjackStore {
  state: () => {
    game: Game | null;
    selectedToken: string;
    selectedBet: number | null;
  };

  [index: string]: any;
}

export const blackjackStore: IBlackjackStore = {
  state: () => ({
    game: null,
    loadingGame: false,
    selectedToken: ELROND.EGLD_TOKEN,
    selectedBet: null,
  }),
  getters: {
    [BLACKJACK_GETTERS.GAME](state) {
      return state.game;
    },
  },
  mutations: {
    [BLACKJACK_MUTATIONS.GAME](state, game) {
      state.game = game;
    },
    [BLACKJACK_MUTATIONS.RESET](state) {
      state.game = null;
    },
  },
  actions: {
    async [BLACKJACK_ACTIONS.GET_GAME]({ commit, getters }, account: Account) {
      if (getters[BLACKJACK_GETTERS.GAME]) {
        return getters[BLACKJACK_GETTERS.GAME];
      }

      const game = await tokenFlipContract.getGame(account);

      commit(BLACKJACK_MUTATIONS.GAME, game);

      return game;
    },
    async [BLACKJACK_ACTIONS.PLAY]({ getters }, { accountElrond, stand, token, amount, type, goNutsPricePerEgld }) {
      storageHelper.setTransactionToWatch(type, accountElrond.nonce);

      return await gamesProxyContract.blackjack(
        accountElrond,
        token,
        amount,
        BLOCKCHAIN.TOKEN_FLIP.TOKENS[token]?.decimals,
        stand,
        getters[REFERRAL_GETTERS.REFERER],
        goNutsPricePerEgld
          ? goNutsPricePerEgld
            .multipliedBy(0.98) // 2% slippage
            .multipliedBy(amount)
            .decimalPlaces(0)
          : null
      );
    },
  },
};
