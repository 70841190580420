
import { computed, ref, watch } from "vue";
import { Toast } from "bootstrap";
import { useStore } from "vuex";
import { BASE_GETTERS, BASE_MUTATIONS } from "@/store";
import Loader from "@/components/helpers/Loader.vue";
import ELROND from "@/constants/elrond";

export default {
  name: "TransactionsToast",
  components: { Loader },
  created() {
    this.EXPLORER_URL = ELROND.EXPLORER + "/transactions/";
  },
  setup() {
    const store = useStore();

    const toast = ref(null);
    const toastRef = ref(null);

    const signToast = ref(null);
    const signToastRef = ref(null);

    const timeout = ref(null);

    const transactions = computed(() => store.getters[BASE_GETTERS.TRANSACTION_TOAST]);
    const signTransactionToast = computed(() => store.getters[BASE_GETTERS.SIGN_TRANSACTION_TOAST]);

    watch(
      () => ({ ...transactions.value }),
      () => {
        if (!Object.keys(transactions.value).length) {
          if (toast.value) {
            toast.value.hide();
          }
          timeout.value = null;

          return;
        }

        let firstDoneTransactionHash: string = null;

        for (const hash in transactions.value) {
          if (null !== transactions.value[hash]) {
            firstDoneTransactionHash = hash;

            break;
          }
        }

        if (!firstDoneTransactionHash) {
          return;
        }

        timeout.value = setTimeout(() => {
          store.commit(BASE_MUTATIONS.REMOVE_TRANSACTION_TOAST, firstDoneTransactionHash);
        }, 5000);
      }
    );

    const hideToast = () => {
      store.commit(BASE_MUTATIONS.RESET_TRANSACTION_TOAST);
    };

    watch(toastRef, () => {
      if (!Object.keys(transactions.value).length || !toastRef.value) {
        return;
      }

      toast.value = new Toast(toastRef.value);
      toast.value.show();
      toastRef.value.addEventListener("hidden.bs.toast", hideToast);
    });

    const hideSignToast = () => {
      store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, null);
    };

    watch(signToastRef, () => {
      if (null === signTransactionToast.value || !signToastRef.value) {
        return;
      }

      signToast.value = new Toast(signToastRef.value);
      signToast.value.show();
      signToastRef.value.addEventListener("hidden.bs.toast", hideSignToast);
    });

    return {
      toastRef,
      transactions,
      signToastRef,
      signTransactionToast,
    };
  },
};
