
import { Dropdown } from "bootstrap";
import { ROUTES } from "@/router/constants";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { USER_GETTERS } from "@/store/user";
import SidebarAccount from "@/components/base/SidebarAccount.vue";
import ENV from "@/constants/env";
import { useRoute } from "vue-router";

export default {
  name: "SidebarCommon",
  components: { SidebarAccount },
  created() {
    this.ROUTES = ROUTES;
    this.ENV = ENV;
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const dropdown = ref(null);
    const dropdownToggleRef = ref(null);

    watch(dropdownToggleRef, () => {
      if (!dropdownToggleRef.value) {
        return;
      }

      dropdown.value = new Dropdown(dropdownToggleRef.value);
    });

    return {
      address: computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]),
      route,
      dropdownToggleRef,
    };
  },
};
