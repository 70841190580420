import { BigUIntType, BinaryCodec, FieldDefinition, Struct, StructType, U64Type } from "@multiversx/sdk-core";
import { BigNumber } from "bignumber.js";
import { NUTS_STAKING_CONSTANTS } from "@/contracts/nutsStaking";

export interface STAKED_POSITION {
  amount: BigNumber;
  nonce: number;
  stakedBlock: number;
  rewardPerShare: any;
  lockingPeriod: number;
  unstakedBlock: number;
  withdrawable: BigNumber;
  baseStruct: Struct | null;
}

class TransformHelper {
  public async mapStakedPosition(nft: any, currentBlock: number): Promise<STAKED_POSITION> {
    const attributes = Buffer.from(nft.attributes, "base64");
    const codec = new BinaryCodec();

    const structType = new StructType('StakingFarmTokenAttributes', [
      new FieldDefinition('staked_block', '', new U64Type()),
      new FieldDefinition('reward_per_share', '', new BigUIntType()),
      new FieldDefinition('locking_period', '', new U64Type()),
      new FieldDefinition('unstaked_block', '', new U64Type()),
    ]);

    const [decoded] = codec.decodeNested(attributes, structType);
    const stakedPosition = decoded.valueOf();

    const stakedBlock = stakedPosition.staked_block.toNumber();
    const lockingPeriod = stakedPosition.locking_period.toNumber();
    const endBlockNonce = stakedBlock + lockingPeriod;

    let withdrawable = new BigNumber(nft.balance);

    if (currentBlock <= endBlockNonce) {
      let unstakedBlock = stakedPosition.unstaked_block.toNumber();

      let initialStakedAmount;
      if (unstakedBlock === 0) {
        initialStakedAmount = new BigNumber(nft.balance);
      } else {
        initialStakedAmount = new BigNumber(nft.balance).multipliedBy(lockingPeriod, 10).dividedToIntegerBy((endBlockNonce - unstakedBlock), 10);
      }

      if (unstakedBlock === 0) {
        unstakedBlock = stakedBlock;
      }

      if (currentBlock <= unstakedBlock + NUTS_STAKING_CONSTANTS.MINIMUM_UNSTAKE_TIME) {
        withdrawable = new BigNumber('0');
      } else {
        withdrawable = initialStakedAmount.multipliedBy((currentBlock - unstakedBlock), 10).dividedToIntegerBy(lockingPeriod, 10);
      }
    }

    return {
      amount: new BigNumber(nft.balance),
      nonce: nft.nonce,
      stakedBlock,
      rewardPerShare: stakedPosition.reward_per_share.toFixed(),
      baseStruct: decoded as Struct,
      lockingPeriod,
      withdrawable,
      unstakedBlock: stakedPosition.unstaked_block.toNumber(),
    };
  }
}

const transformHelper = new TransformHelper();

export default transformHelper;
