import elrondHelper from "@/helpers/elrond";
import { NftStakingContract } from "@/contracts/nftStaking";
import storageHelper from "@/helpers/storage";
import TRANSACTION_TYPES from "@/constants/transactionTypes";
import { USER_GETTERS } from "@/store/user";
import nftStakingClient from "@/api/nftStaking";
import { NftStakedPosition, NftStakingModel, NftStakingRewardModel } from "@/api/models/nftStaking.model";
import { Account, Transaction } from "@multiversx/sdk-core/out";
import BLOCKCHAIN from "@/constants/blockchain";
import { NftUpgradeInfoModel } from "@/api/models/nftUpgradeInfo.model";
import generalClient from "@/api/general";
import { NftUpgradeContract } from "@/contracts/nftUpgrade";
import { Nonce } from "@multiversx/sdk-network-providers/out/primitives";

export const NFT_STAKING_GETTERS = {
  CURRENT_BLOCK: "nftStakingCurrentBlock",
  POOL: "nftStakingPool",
  UPGRADE_INFO: "nftUpgradeInfo",
};

export const NFT_STAKING_MUTATIONS = {
  CURRENT_BLOCK: "nftStakingCurrentBlock",
  POOL: "nftStakingPool",
  UPGRADE_INFO: "nftUpgradeInfo",
};

export const NFT_STAKING_ACTIONS = {
  GET_NFT_POOL: "nftStakingGetPool",
  STAKE: "nftStakingStake",
  UNSTAKE: "nftStakingUnstake",
  CLAIM_REWARDS: "nftStakingClaimRewards",
  UPGRADE: "nftStakingUpgrade",
};

interface NFT_STAKING_STORE_STATE {
  currentBlock?: number;
  pool: NftStakingModel;
  upgradeInfo: NftUpgradeInfoModel;
}

export const nftStakingStore = {
  state: (): NFT_STAKING_STORE_STATE => ({
    currentBlock: null,
    pool: null,
    upgradeInfo: null,
  }),
  getters: {
    [NFT_STAKING_GETTERS.CURRENT_BLOCK](state) {
      return state.currentBlock;
    },
    [NFT_STAKING_GETTERS.POOL](state) {
      return state.pool;
    },
    [NFT_STAKING_GETTERS.UPGRADE_INFO](state) {
      return state.upgradeInfo;
    },
  },
  mutations: {
    [NFT_STAKING_MUTATIONS.CURRENT_BLOCK](state, currentBlock) {
      state.currentBlock = currentBlock;
    },
    [NFT_STAKING_MUTATIONS.POOL](state, pool) {
      state.pool = pool;
    },
    [NFT_STAKING_MUTATIONS.UPGRADE_INFO](state, upgradeInfo) {
      state.upgradeInfo = upgradeInfo;
    },
  },
  actions: {
    async [NFT_STAKING_ACTIONS.GET_NFT_POOL]({ commit, getters }, nftPoolType) {
      const address = getters[USER_GETTERS.ADDRESS_ELROND];

      const apiResponse = await nftStakingClient.nftStakingPool(nftPoolType, address);

      if (!apiResponse) {
        commit(NFT_STAKING_MUTATIONS.CURRENT_BLOCK, null);
        commit(NFT_STAKING_MUTATIONS.POOL, null);

        return;
      }

      const currentBlock = await elrondHelper.getCurrentBlock(
        elrondHelper.getAddressShard(apiResponse.reward.contractAddress)
      );

      commit(NFT_STAKING_MUTATIONS.CURRENT_BLOCK, currentBlock);
      commit(NFT_STAKING_MUTATIONS.POOL, apiResponse);

      if (BLOCKCHAIN.NFT_POOL_TYPES.NUTS === nftPoolType && !getters[NFT_STAKING_GETTERS.UPGRADE_INFO]) {
        commit(NFT_STAKING_MUTATIONS.UPGRADE_INFO, await generalClient.nftUpgradeInfo());
      }
    },
    async [NFT_STAKING_ACTIONS.STAKE](
      _,
      {
        type,
        reward,
        accountElrond,
        nonces,
        stakedPosition,
      }: {
        type: string;
        reward: NftStakingRewardModel;
        accountElrond: Account;
        nonces: number[];
        stakedPosition: NftStakedPosition;
      }
    ) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NFT_STAKING.STAKE, accountElrond.nonce);

      const contract = NftStakingContract.getContract(type, reward.contractAddress, reward.metaToken, reward.token);

      return await contract.stake(accountElrond, nonces, stakedPosition?.nonce);
    },
    async [NFT_STAKING_ACTIONS.UNSTAKE](
      _,
      {
        type,
        reward,
        accountElrond,
        stakedPosition,
      }: {
        type: string;
        reward: NftStakingRewardModel;
        accountElrond: Account;
        stakedPosition: NftStakedPosition;
      }
    ) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NFT_STAKING.UNSTAKE, accountElrond.nonce);

      const contract = NftStakingContract.getContract(type, reward.contractAddress, reward.metaToken, reward.token);

      return await contract.unstake(accountElrond, stakedPosition.nonce, stakedPosition.nbNfts);
    },
    async [NFT_STAKING_ACTIONS.CLAIM_REWARDS](
      _,
      {
        type,
        reward,
        accountElrond,
        stakedPosition,
      }: {
        type: string;
        reward: NftStakingRewardModel;
        accountElrond: Account;
        stakedPosition: NftStakedPosition;
      }
    ) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NFT_STAKING.CLAIM, accountElrond.nonce);

      const contract = NftStakingContract.getContract(type, reward.contractAddress, reward.metaToken, reward.token);

      return await contract.claimRewards(accountElrond, stakedPosition.nonce, stakedPosition.nbNfts);
    },
    async [NFT_STAKING_ACTIONS.UPGRADE](
      { getters },
      {
        accountElrond,
        nonces,
      }: {
        accountElrond: Account;
        nonces: number[];
      }
    ) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NFT_STAKING.UPGRADE, accountElrond.nonce);

      const nftUpgradeInfo: NftUpgradeInfoModel = getters[NFT_STAKING_GETTERS.UPGRADE_INFO];

      const contract = NftUpgradeContract.getContract(
        nftUpgradeInfo.contract,
        nftUpgradeInfo.nftTokenId,
        nftUpgradeInfo.upgradeNftTokenId
      );

      return await contract.upgrade(accountElrond, nonces);
    },
  },
};
