import { createApp } from "vue";
import vSelect from "vue-select";

import App from "./App.vue";
import store from "./store";
import i18n from "@/plugins/i18n";
import router from "@/router";

import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "vue-select/dist/vue-select.css";

import "./assets/css/app.scss";
import { vTooltip } from "@/plugins/tooltip";

let newUrl = null;

if (-1 !== window.location.href.indexOf('www.')) {
  newUrl = window.location.href.replace('www.', '');
}

if (-1 !== window.location.href.indexOf('memeverce.com')) {
  newUrl = window.location.href.replace('memeverce.com', 'elrondbet.net');
}

if (newUrl) {
  window.location.replace(newUrl);
}

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);

app.directive('tooltip', vTooltip);

app.component('v-select', vSelect);

app.mount('#app');
