
import { computed, onMounted, ref } from "vue";
import "animate.css";
import ThemeButton from "../components/ThemeButton.vue";
import { useStore } from "vuex";
import { BASE_ACTIONS, BASE_GETTERS } from "@/store";
import BLOCKCHAIN from "@/constants/blockchain";
import ELROND from "@/constants/elrond";
import formatterHelper from "@/helpers/formatter";
import { ROUTES } from "@/router/constants";

import "./style.scss";

export default {
  name: "home",
  components: { ThemeButton },
  created() {
    this.EXPLORER_URL = ELROND.EXPLORER;
    this.REWARDS_ADDRESS = BLOCKCHAIN.REWARDS_ADDRESS;
    this.formatterHelper = formatterHelper;
    this.CARD_NFT_TOKEN_IDENTIFIER = BLOCKCHAIN.CARD_NFT_TOKEN_IDENTIFIER;
    this.ROUTES = ROUTES;
    this.BLOCKCHAIN = BLOCKCHAIN;
  },
  setup() {
    const flipped = ref(false);

    const getImgUrl = (cardname) => {
      return require(`../assets/images/cards/${cardname}.png`);
    };

    const store = useStore();
    const rewardsBalance = computed(() => store.getters[BASE_GETTERS.REWARDS_BALANCE]);

    onMounted(() => {
      store.dispatch(BASE_ACTIONS.GET_REWARDS_BALANCE);
    });

    return {
      getImgUrl,
      flipped,
      rewardsBalance,
    };
  },
};
