import ELROND from "@/constants/elrond";
import { Address, TokenTransfer } from "@multiversx/sdk-core";
import { BigNumber } from "bignumber.js";
import BLOCKCHAIN from "@/constants/blockchain";

const formatterHelper: any = {
  formatAddress: (address: Address | string): string => {
    const str = address instanceof Address ? address.bech32() : address;

    return str.substring(0, 7) + "..." + str.substring(str.length - 7);
  },
  formatTokenTransfer: (
    tokenTransfer: TokenTransfer | BigNumber | null,
    length = 14,
    token = null,
    round = false
  ): string => {
    if (null === tokenTransfer || undefined === tokenTransfer) {
      return `0 ${token || "EGLD"}`;
    }

    if (!(tokenTransfer instanceof TokenTransfer)) {
      tokenTransfer = new BigNumber(tokenTransfer);

      if (!token) {
        tokenTransfer = TokenTransfer.egldFromBigInteger(tokenTransfer.decimalPlaces(0));
      } else {
        tokenTransfer = TokenTransfer.fungibleFromBigInteger(token, tokenTransfer.decimalPlaces(0), BLOCKCHAIN.TOKENS[token]?.decimals);

        token = BLOCKCHAIN.TOKENS[token]?.name;
      }
    }

    let str = tokenTransfer.amountAsBigInteger.shiftedBy(-tokenTransfer.numDecimals).toFixed(tokenTransfer.numDecimals);

    if (length) {
      length = Math.max(length, str.indexOf(".") + 1);
    }

    if (length && str.length > length) {
      str = str.substring(0, length);
    }

    if (null === token) {
      token = BLOCKCHAIN.TOKENS[tokenTransfer.tokenIdentifier]?.name;

      if (!token) {
        token = tokenTransfer.tokenIdentifier.split("-")[0];
      }
    }

    let number = Number.parseFloat(str);

    if (round) {
      const roundTo = number.toString().search(/([0-9])\1\1/);
      if (roundTo !== -1) {
        number = Math.round(number * (10 ** roundTo)) / (10 ** roundTo);
      }
    }

    // Add commas to formatting
    str = new Intl.NumberFormat(undefined, { maximumFractionDigits: 6, minimumSignificantDigits: 3, compactDisplay: "short" }).format(number);

    // Remove dangling zeros
    if (str.indexOf(".") !== -1) {
      let index = str.length - 1;
      while ("0" === str[index]) {
        index--;
      }
      str = str.substring(0, index + 1);
    }

    if ("." === str[str.length - 1]) {
      str = str.substring(0, str.length - 1);
    }

    return `${str} ${null !== token ? token : "EGLD"}`;
  },
  formatEgld: (egld: number): string => {
    return new BigNumber(egld).dividedToIntegerBy(ELROND.EGLD_DENOMINATOR).toString() + " EGLD";
  },
  formatDateTime(date) {
    if (!date) {
      return "";
    }

    // @ts-ignore
    return new Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "short" }).format(new Date(date * 1000));
  },
  formatDate(date) {
    if (!date) {
      return "";
    }

    // @ts-ignore
    return new Intl.DateTimeFormat(undefined, { dateStyle: "short" }).format(new Date(date * 1000));
  },
  formatTime(date) {
    if (!date) {
      return "";
    }

    // @ts-ignore
    return new Intl.DateTimeFormat(undefined, { timeStyle: "short" }).format(new Date(date * 1000));
  },
  getHoursRemaining(date) {
    const now = Date.now();
    const difference = date * 1000 - now;

    if (0 > difference) {
      return 0;
    }

    return Math.round(difference / 3.6e6);
  },
  formatBlockDifference(currentBlock: number, endBlock: number, round = false) {
    const value = currentBlock > endBlock ? 0 : (endBlock - currentBlock) * ELROND.TIME_PER_BLOCK;

    const days = value / (60 * 60 * 24);

    if (days <= 0) {
      return "";
    }

    if (round && days > 240) {
      return new Intl.RelativeTimeFormat(undefined).format(1, "year");
    }

    if (round && days > 180) {
      return new Intl.RelativeTimeFormat(undefined).format(6, "months") + "+";
    }

    if (round && days > 60) {
      return new Intl.RelativeTimeFormat(undefined).format(days / 30, "months");
    }

    // @ts-ignore
    return new Intl.RelativeTimeFormat(undefined).format(days, "days");
  },
  getDaysFromBlock(block: number) {
    return block / ELROND.BLOCKS_PER_DAY;
  },
  formatToken: (token: string): string => {
    return token.split("-")[0];
  },
};

export default formatterHelper;
