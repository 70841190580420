import elrondHelper from "@/helpers/elrond";
import { USER_GETTERS } from "@/store/user";
import { PoolStakedPosition, PoolStakingModel, PoolStakingRewardModel } from "@/api/models/poolsStaking.model";
import poolStaking from "@/api/poolStaking";
import storageHelper from "@/helpers/storage";
import TRANSACTION_TYPES from "@/constants/transactionTypes";
import { PoolStakingContract } from "@/contracts/poolStaking";
import { Account } from "@multiversx/sdk-core/out";
import { BigNumber } from "bignumber.js";
import { NftModel } from "@/api/models/nft.model";

export const POOLS_STAKING_GETTERS = {
  POOLS: "poolsStakingPools",
  CURRENT_BLOCK: "poolsStakingCurrentBlock",
};

export const POOLS_STAKING_MUTATIONS = {
  POOLS: "poolsStakingPools",
  CURRENT_BLOCK: "poolsStakingCurrentBlock",
};

export const POOLS_STAKING_ACTIONS = {
  GET_POOLS: "poolsStakingGetPools",
  STAKE: "poolsStakingStake",
  UNSTAKE: "poolsStakingUnstake",
  CLAIM_REWARDS: "poolsStakingClaimRewards",
};

interface POOLS_STAKING_STORE_STATE {
  currentBlock?: number;
  pools?: PoolStakingModel[];
}

export const poolsStakingStore = {
  state: (): POOLS_STAKING_STORE_STATE => ({
    currentBlock: null,
    pools: null,
  }),
  getters: {
    [POOLS_STAKING_GETTERS.CURRENT_BLOCK](state) {
      return state.currentBlock;
    },
    [POOLS_STAKING_GETTERS.POOLS](state) {
      return state.pools;
    },
  },
  mutations: {
    [POOLS_STAKING_MUTATIONS.CURRENT_BLOCK](state, currentBlock) {
      state.currentBlock = currentBlock;
    },
    [POOLS_STAKING_MUTATIONS.POOLS](state, pools) {
      state.pools = pools;
    },
  },
  actions: {
    async [POOLS_STAKING_ACTIONS.GET_POOLS]({ commit, getters }) {
      const address = getters[USER_GETTERS.ADDRESS_ELROND];

      const apiResponse = await poolStaking.allPools(address);

      if (!apiResponse || !apiResponse.length) {
        commit(POOLS_STAKING_MUTATIONS.CURRENT_BLOCK, null);
        commit(POOLS_STAKING_MUTATIONS.POOLS, null);

        return;
      }

      const currentBlock = await elrondHelper.getCurrentBlock(
        elrondHelper.getAddressShard(apiResponse[0].reward.poolAddress)
      );

      commit(POOLS_STAKING_MUTATIONS.CURRENT_BLOCK, currentBlock);
      commit(POOLS_STAKING_MUTATIONS.POOLS, apiResponse);
    },
    async [POOLS_STAKING_ACTIONS.STAKE](
      _,
      {
        type,
        reward,
        accountElrond,
        amount,
        stakedPosition,
        boostToken,
      }: {
        type: string;
        reward: PoolStakingRewardModel;
        accountElrond: Account;
        amount: BigNumber.Value;
        stakedPosition: PoolStakedPosition;
        boostToken?: NftModel;
      }
    ) {
      storageHelper.setTransactionToWatch(
        TRANSACTION_TYPES.POOL_STAKING.STAKE(type, stakedPosition.nonce),
        accountElrond.nonce
      );

      const contract = PoolStakingContract.getContract(
        reward.poolAddress,
        reward.metaToken,
        reward.token,
        reward.tokenDecimals
      );

      return await contract.stake(
        accountElrond,
        amount,
        stakedPosition.nonce ? stakedPosition.amount.amountAsBigInteger : null,
        stakedPosition.nonce || null,
        boostToken?.collection,
        new BigNumber(boostToken?.balance),
        boostToken?.nonce
      );
    },
    async [POOLS_STAKING_ACTIONS.UNSTAKE](
      _,
      {
        type,
        reward,
        amount,
        accountElrond,
        stakedPosition,
      }: {
        type: string;
        reward: PoolStakingRewardModel;
        amount: BigNumber.Value;
        accountElrond: Account;
        stakedPosition: PoolStakedPosition;
      }
    ) {
      storageHelper.setTransactionToWatch(
        TRANSACTION_TYPES.POOL_STAKING.UNSTAKE(type, stakedPosition.nonce),
        accountElrond.nonce
      );

      const contract = PoolStakingContract.getContract(
        reward.poolAddress,
        reward.metaToken,
        reward.token,
        reward.tokenDecimals
      );

      const originalAmountNb = stakedPosition.originalAmount.amountAsBigInteger
        .shiftedBy(-reward.tokenDecimals)
        .toString();

      if (amount.toString() === originalAmountNb) {
        amount = stakedPosition.amount.amountAsBigInteger.shiftedBy(-reward.tokenDecimals);
      } else if (stakedPosition.boosted) {
        amount = new BigNumber(amount)
          .multipliedBy(105, 10)
          .dividedBy(100, 10);
      }

      return await contract.unstake(accountElrond, amount, stakedPosition.nonce);
    },
    async [POOLS_STAKING_ACTIONS.CLAIM_REWARDS](
      _,
      {
        type,
        reward,
        accountElrond,
        stakedPosition,
        boostToken,
      }: {
        type: string;
        reward: PoolStakingRewardModel;
        accountElrond: Account;
        stakedPosition: PoolStakedPosition;
        boostToken?: NftModel;
      }
    ) {
      storageHelper.setTransactionToWatch(
        TRANSACTION_TYPES.POOL_STAKING.CLAIM(type, stakedPosition.nonce),
        accountElrond.nonce
      );

      const contract = PoolStakingContract.getContract(
        reward.poolAddress,
        reward.metaToken,
        reward.token,
        reward.tokenDecimals
      );

      return await contract.claimRewards(
        accountElrond,
        stakedPosition.amount.amountAsBigInteger,
        stakedPosition.nonce,
        boostToken?.collection,
        new BigNumber(boostToken?.balance),
        boostToken?.nonce
      );
    },
  },
};
