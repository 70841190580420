import { createI18n } from "vue-i18n"

import { enMessages } from "@/translations/en"
import GENERAL from "@/constants/general";

export default createI18n({
  locale: GENERAL.DEFAULT_LANGUAGE,
  fallbackLocale: GENERAL.DEFAULT_LANGUAGE,
  messages: {
    en: enMessages
  },
  legacy: false,
});
