import ENV from "@/constants/env";
import ELROND from "@/constants/elrond";
import { BigNumber } from "bignumber.js";

const COLLECTIONS: { [key: string]: string } = {};

ENV.COLLECTIONS.split(";").forEach((value) => {
  const split = value.split(":");

  COLLECTIONS[split[0]] = split[1];
});

interface ITOKEN {
  name: string;
  logo: string;
  decimals: number;
  disabled?: boolean;
}

const TOKENS: { [key: string]: ITOKEN } = {};

ENV.TOKENS.split(";").forEach((value) => {
  const split = value.split(":");

  const tokenId = split[0];
  const token = {
    name: tokenId.split("-")[0],
    logo: split[1],
    decimals: Number.parseInt(split[2]),
  };

  TOKENS[tokenId] = token;
});

const NFT_POOLS: { [type: string]: string } = {};

ENV.NFT_POOLS.split(";").forEach((value) => {
  const split = value.split(":");

  NFT_POOLS[split[0]] = split[1];
});

const BLOCKCHAIN = {
  NFT_TOKEN_IDENTIFIER: ENV.NFT_TOKEN_IDENTIFIER,
  NEXT_GEN_NFT_TOKEN_IDENTIFIER: ENV.NEXT_GEN_NFT_TOKEN_IDENTIFIER,
  TOKEN_IDENTIFIER: ENV.TOKEN_IDENTIFIER,
  TOKEN_IDENTIFIER_WEGLD: ENV.TOKEN_IDENTIFIER_WEGLD,

  META_STAKING_TOKEN_IDENTIFIER: ENV.META_STAKING_TOKEN_IDENTIFIER,
  STAKING_TOKEN_PER_BLOCK: new BigNumber(parseInt(ENV.STAKING_TOKEN_PER_BLOCK), 10),
  STAKING_MAX_APR: Number.parseInt(ENV.STAKING_MAX_APR),

  REWARDS_ADDRESS: ENV.REWARDS_ADDRESS,
  CARD_NFT_TOKEN_IDENTIFIER: ENV.CARD_NFT_TOKEN_IDENTIFIER,

  NUTS_PER_TICKET: new BigNumber(parseInt(ENV.NUTS_PER_TICKET), 10),

  CONTRACTS: {
    TOKEN_FLIP: ENV.CONTRACT_TOKEN_FLIP,
    HIDE_AND_SEEK: ENV.CONTRACT_HIDE_AND_SEEK,
    ONE_VS_ONE: ENV.CONTRACT_ONE_VS_ONE,
    GAMES_PROXY: ENV.CONTRACT_GAMES_PROXY,
    NUTS_STAKING: ENV.CONTRACT_NUTS_STAKING,
    NUTS_ROULETTE: ENV.CONTRACT_NUTS_ROULETTE,
  },

  COLLECTIONS: {
    [ENV.NFT_TOKEN_IDENTIFIER]: "ElrondBet",
    ...COLLECTIONS,
  },
  NFT_POOLS: NFT_POOLS,
  NFT_POOL_TYPES: {
    NUTS: 'NUTS',
    NEXTGEN_NUTS: 'NEXTGEN_NUTS',
    VIP: 'VIP',
  },
  POOL_TYPES: {
    NUTS_EGLD: 'NUTS_EGLD',
    NUTS_VIP: 'NUTS_VIP',
    EGLDLP_DEEZ: 'EGLDLP_DEEZ',
    EGLDLP_NUTS: 'EGLDLP_NUTS',
  },
  TOKENS: {
    [ENV.TOKEN_IDENTIFIER_WEGLD]: {
      name: ELROND.WEGLD_TOKEN,
      logo: ENV.TOKEN_IDENTIFIER_WEGLD_MAINNET,
      decimals: 18,
      disabled: true,
    },
    [ELROND.EGLD_TOKEN]: {
      name: ELROND.EGLD_TOKEN,
      logo: ELROND.EGLD_TOKEN,
      decimals: 18,
      disabled: false,
    },
    [ENV.TOKEN_IDENTIFIER]: {
      name: "NUTS",
      logo: ENV.TOKEN_IDENTIFIER_MAINNET,
      decimals: 6,
      disabled: false,
    },
    ...TOKENS,
  },
  GAME_BET: {
    [ELROND.EGLD_TOKEN]: [0.05, 0.1, 0.15, 0.25, 0.5],
    NUTS: [10000, 25000, 50000, 75000, 100000],
    USDC: [2, 5, 10, 15, 20],
    SEGLD: [0.05, 0.1, 0.15, 0.25, 0.5],
    JEX: [1000, 2500, 5000, 7500, 10000],
    RARE: [100, 250, 500, 750, 1000],
    KRO: [1500, 3750, 7500, 11250, 15000],
    EFTR: [500, 1250, 2500, 4000, 5000],
    MERMAID: [5000, 15000, 25000, 37500, 50000],
    WATER: [2500, 6250, 12500, 18750, 25000],
    BHAT: [150, 375, 750, 1125, 1500],
    GIANT: [1000, 2500, 5000, 7500, 10000],
    MOOVE: [10, 25, 50, 75, 100],
    PADAWAN: [25, 50, 100, 175, 250],
    BFY: [5, 12.5, 25, 37.5, 50],
  },

  HIDE_AND_SEEK: {
    GAME_MODES: {
      3: "X3 - 33% Luck🍀",
      2: "X2 - 50% Luck🍀",
    },
  },
  TOKEN_FLIP: {
    TOKENS: {
      [ELROND.EGLD_TOKEN]: {
        name: ELROND.EGLD_TOKEN,
        logo: ELROND.EGLD_TOKEN,
        decimals: 18,
      },
      [ENV.TOKEN_IDENTIFIER]: {
        name: "NUTS",
        logo: ENV.TOKEN_IDENTIFIER_MAINNET,
        decimals: 6,
      },
      ...TOKENS,
    },
    GAME_BET: {
      [ELROND.EGLD_TOKEN]: [0.1, 0.25, 0.5, 0.75, 1],
      NUTS: [20000, 50000, 100000, 150000, 200000],
      USDC: [4, 10, 20, 30, 40],
      SEGLD: [0.1, 0.25, 0.5, 0.75, 1],
      JEX: [2000, 5000, 10000, 15000, 20000],
      RARE: [200, 500, 1000, 1500, 2000],
      KRO: [3000, 7500, 15000, 22500, 30000],
      EFTR: [1000, 2500, 5000, 7500, 10000],
      MERMAID: [10000, 30000, 50000, 75000, 100000],
      WATER: [5000, 12500, 25000, 37500, 50000],
      BHAT: [300, 750, 1500, 2250, 3000],
      GIANT: [2000, 5000, 10000, 15000, 20000],
      MOOVE: [20, 50, 100, 150, 200],
      PADAWAN: [50, 125, 250, 375, 500],
      BFY: [10, 25, 50, 75, 100],
    },
  },

  // Blackjack
  BLACKJACK: {
    CHIPS: [
      'secondary',
      'danger',
      'success',
      'primary',
      'dark',
    ],
  },

  NUTS_IMAGES_URL: "https://media.elrond.com/nfts/asset/bafybeiahsp6clcag7fj25bimkypko6lv65yjhplp5stae5uf4gcuexqr2u/",
  NUTS_TOKEN_IMAGE_URL: ELROND.TOKEN_LOGO.replace('{TOKEN}', ENV.TOKEN_IDENTIFIER_MAINNET),

  CUSTOM_TOKENS: {
    JEX: "JEX",
    KRO: "KRO",
    WATER: "WATER",
    RARE: "RARE",
    MERMAID: "MERMAID",
    PADAWAN: "PADAWAN",
    BFY: "BFY",
  },
};

export default BLOCKCHAIN;
