import api, { API_PATHS } from "@/api/index";
import { NftStakingModel } from "@/api/models/nftStaking.model";
import { BigNumber } from "bignumber.js";
import BLOCKCHAIN from "@/constants/blockchain";
import { TokenTransfer } from "@multiversx/sdk-core/out";
import ELROND from "@/constants/elrond";
import xoxnoApi, { NftPrice } from "@/api/xoxnoApi";
import oneDexApi from "@/api/onedexApi";

class NftStakingClient {
  async nftStakingPool(type: string, address?: string): Promise<NftStakingModel | null> {
    try {
      const response = await api.get(API_PATHS.NFT_STAKING_POOL, { type }, { address });
      const data = response.data;

      let floorPrice: NftPrice | null;
      try {
        floorPrice = await xoxnoApi.getNftFloorPrice(data.reward.token);
      } catch (e) {
        floorPrice = null;
      }

      let floorPriceApr;
      if (floorPrice) {
        if (type === BLOCKCHAIN.NFT_POOL_TYPES.VIP) {
          floorPriceApr = new BigNumber(data.reward.rewardPerNftPerDay)
            .multipliedBy(36500, 10)
            .dividedBy(ELROND.EGLD_DENOMINATOR, 10)
            .dividedToIntegerBy(floorPrice.price)
            .toNumber();
        } else {
          try {
            const rewardTokenPrice = await oneDexApi.getTokenPrice(data.reward.rewardToken);

            const rewardsInUsdPerDay = new BigNumber(data.reward.rewardPerDay)
              .multipliedBy(rewardTokenPrice)
              .dividedBy(Math.pow(10, data.reward.rewardTokenDecimals), 10);

            const costInUsd = new BigNumber(data.reward.nbNftsStaked)
              .multipliedBy(floorPrice.usdPrice);

            console.log('reward token price', rewardTokenPrice);
            console.log('reward per day', rewardsInUsdPerDay.toFixed());
            console.log('cost in used', costInUsd.toFixed());

            floorPriceApr = rewardsInUsdPerDay.multipliedBy(36500).dividedToIntegerBy(costInUsd).toNumber();

            console.log('floor price apr', floorPriceApr);
          } catch (e) {
            console.error(e);
          }
        }
      }

      return {
        ...data,
        reward: {
          ...data.reward,
          rewardPerDay: TokenTransfer.fungibleFromBigInteger(
            data.reward.rewardToken,
            data.reward.rewardPerDay,
            data.reward.rewardTokenDecimals
          ),
          rewardPerNftPerDay: new BigNumber(data.reward.rewardPerNftPerDay),
          rewardCapacity: new BigNumber(data.reward.rewardCapacity),
          floorPriceApr,
        },
        positions: (data?.positions || []).map((position) => ({
          ...position,
          rewardPerShare: new BigNumber(position.rewardPerShare),
          calculatedReward:
            type === BLOCKCHAIN.NFT_POOL_TYPES.VIP
              ? TokenTransfer.egldFromBigInteger(position.calculatedReward)
              : TokenTransfer.fungibleFromBigInteger(
                  BLOCKCHAIN.TOKEN_IDENTIFIER,
                  position.calculatedReward,
                  BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
                ),
        })),
      };
    } catch (e) {
      console.error(e);

      return null;
    }
  }
}

const nftStakingClient = new NftStakingClient();

export default nftStakingClient;
