import BLOCKCHAIN from "@/constants/blockchain";
import elrondApiHelper from "@/helpers/elrondApi";
import { BytesValue, TokenTransfer } from "@multiversx/sdk-core";
import gamesProxyContract, { GAMES_PROXY_FUNCTIONS } from "@/contracts/gamesProxy";
import elrondHelper from "@/helpers/elrond";
import tokenFlipContract, { TOKEN_FLIP_FUNCTIONS } from "@/contracts/tokenFlip";
import { REFERRAL_GETTERS } from "@/store/referral";
import { TransactionDecoder } from "@multiversx/sdk-transaction-decoder/lib/src/transaction.decoder";
import { TransactionOnNetwork } from "@multiversx/sdk-network-providers/out";
import { NumericalValue, ResultsParser } from "@multiversx/sdk-core/out";
import { BigNumber } from "bignumber.js";
import ELROND from "@/constants/elrond";

export const TOKEN_FLIP_GETTERS = {
  RECENT_GAMES: "tokenFlipRecentGames",
};

export const TOKEN_FLIP_MUTATIONS = {
  RECENT_GAMES: "tokenFlipRecentGames",
};

export const TOKEN_FLIP_ACTIONS = {
  PLAY: "tokenFlipPlay",
  GET_RECENT_GAMES: "tokenFlipGetRecentGames",
};

interface RECENT_GAME {
  index: number;
  hash: string;
  address: string;
  tokenTransfer: TokenTransfer;
  timesWon: number;
  timesLost: number;
  goNuts: boolean;
}

interface ITokenFlipStore {
  state: () => {
    recentGames: RECENT_GAME[];
  };

  [index: string]: any;
}

export const tokenFlipStore: ITokenFlipStore = {
  state: () => ({
    recentGames: [],
  }),
  getters: {
    [TOKEN_FLIP_GETTERS.RECENT_GAMES](state) {
      return state.recentGames;
    },
  },
  mutations: {
    [TOKEN_FLIP_MUTATIONS.RECENT_GAMES](state, recentGames) {
      state.recentGames = recentGames;
    },
  },
  actions: {
    async [TOKEN_FLIP_ACTIONS.PLAY]({ getters }, { accountElrond, token, amount, times, goNutsPricePerEgld }) {
      const extraSlippage = amount > BLOCKCHAIN.TOKEN_FLIP.GAME_BET[ELROND.EGLD_TOKEN][2] ? 0.05 : 0;

      return await gamesProxyContract.tokenFlip(
        accountElrond,
        token,
        amount * times,
        BLOCKCHAIN.TOKENS[token].decimals,
        times,
        getters[REFERRAL_GETTERS.REFERER],
        goNutsPricePerEgld
          ? goNutsPricePerEgld
              .multipliedBy(0.99 - times * 0.01 - extraSlippage) // 1% slippage + 1% additional per times
              .multipliedBy(amount * times)
              .decimalPlaces(0)
          : null
      );
    },
    async [TOKEN_FLIP_ACTIONS.GET_RECENT_GAMES]({ commit, getters }) {
      if (getters[TOKEN_FLIP_GETTERS.RECENT_GAMES].length) {
        return;
      }

      const response = await elrondApiHelper.getTransactionsToProxy(GAMES_PROXY_FUNCTIONS.TOKEN_FLIP);

      await tokenFlipContract.getContractAbi();

      const decoder = new TransactionDecoder();

      let index = 0;

      const recentGames: RECENT_GAME[] = response
        .map((transaction: TransactionOnNetwork) => {
          try {
            const results = elrondHelper.parseCustomResults(
              transaction,
              tokenFlipContract.contract.getEndpoint(TOKEN_FLIP_FUNCTIONS.PLAY)
            );

            if (!results) {
              return null;
            }

            const metadata = decoder.getTransactionMetadata({
              sender: transaction.sender.bech32(),
              receiver: transaction.receiver.bech32(),
              data: transaction.data.toString("base64"),
              value: transaction.value.toString(),
            });

            const { firstValue, secondValue } = results;
            const timesWon = (firstValue as NumericalValue).value.toNumber();
            const timesLost = (secondValue as NumericalValue).value.toNumber();
            const timesTotal = timesWon + timesLost;

            const tokenTransfer = !metadata.transfers
              ? TokenTransfer.egldFromBigInteger(
                  new BigNumber(transaction.value).dividedToIntegerBy(new BigNumber(timesTotal))
                )
              : TokenTransfer.fungibleFromBigInteger(
                  metadata.transfers[0].properties.identifier,
                  new BigNumber(metadata.transfers[0].value.toString()).dividedToIntegerBy(new BigNumber(timesTotal)),
                  BLOCKCHAIN.TOKENS[metadata.transfers[0].properties.identifier]?.decimals || 18
                );

            index++;

            return {
              index,
              hash: transaction.hash,
              address: transaction.sender.bech32(),
              tokenTransfer,
              timesWon,
              timesLost,
              goNuts: !!metadata.functionArgs?.[2],
            };
          } catch (e) {
            return null;
          }
        })
        .filter((value) => null !== value);

      commit(TOKEN_FLIP_MUTATIONS.RECENT_GAMES, recentGames);
    },
  },
};
