import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import ENV from "../constants/env";
import storageHelper from "@/helpers/storage";
import GENERAL from "@/constants/general";

export const API_PATHS = {
  STAKING_POOLS: "/staking/pools",

  NFT_STAKING_POOL: "/nft-staking/pools/{type}",

  NFT_UPGRADE_INFO: "/nft-upgrade/info",
};

class Api {
  public client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: ENV.MICROSERVICE_URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 60000,
    });

    // this.client.interceptors.request.use(
    //   (config) => {
    //     if (config.baseURL === ENV.MICROSERVICE_URL && !config.headers.Authorization) {
    //       const token = storageHelper.getBackendAccessToken();
    //
    //       if (token) {
    //         config.headers.Authorization = `Bearer ${token}`;
    //       }
    //     }
    //
    //     return config;
    //   },
    //   (error) => Promise.reject(error)
    // );
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const { default: store, BASE_MUTATIONS } = await import("@/store");

        if (
          GENERAL.STATUS_CODE_ACCESS_DENIED === error.response.status &&
          error.response.config.headers.Authorization
        ) {
          storageHelper.clearLogins(true);
          window.location.reload();
        } else if (GENERAL.STATUS_CODE_INTERNAL_SERVER_ERROR === error.response.status) {
          store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
            variant: "danger",
            message: "messages.error.general",
          });
        }

        return Promise.reject(error);
      }
    );
  }

  async get(
    url: string,
    params: { [key: string]: any } = {},
    queryParams: { [key: string]: any } = {}
  ): Promise<AxiosResponse> {
    return await this.client.get(Api.withPathParams(url, params), { params: queryParams });
  }

  async post(url: string, data: { [key: string]: any } = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await this.client.post(url, data, config);
  }

  private static withPathParams(url: string, params: { [key: string]: any }) {
    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        continue;
      }

      url = url.replace(`{${key}}`, value);
    }

    return url;
  }
}

const api = new Api();

export default api;
