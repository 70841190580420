import elrondHelper, { WALLET_LOGIN_TYPE } from "@/helpers/elrond";
import storageHelper from "@/helpers/storage";
import elrondApiHelper, { NFT_POST } from "@/helpers/elrondApi";
import storeHelper, { LIST } from "@/helpers/store";
import { Account, TokenTransfer, TransactionWatcher } from "@multiversx/sdk-core";
import { WalletConnectV2Provider } from "@multiversx/sdk-wallet-connect-provider";
import { HWProvider } from "@multiversx/sdk-hw-provider";
import ELROND from "@/constants/elrond";
import { REFERRAL_MUTATIONS } from "@/store/referral";
import { PATHS_EN, ROUTES } from "@/router/constants";
import BLOCKCHAIN from "@/constants/blockchain";
import GENERAL from "@/constants/general";

export const USER_GETTERS = {
  ADDRESS_ELROND: "userAddressElrond",
  ACCOUNT_ELROND: "userAccountElrond",
  NFTS: "userNFTs",
  NEXT_GEN_NFTS: "userNextGenNFTs",
  CARD_NFTS: "userCardNFTs",
  TOKENS: "userTokens",
};

export const USER_MUTATIONS = {
  ADDRESS_ELROND: "userAddressElrond",
  ACCOUNT_ELROND: "userAccountElrond",
  NFTS: "userNFTs",
  NEXT_GEN_NFTS: "userNextGenNFTs",
  CARD_NFTS: "userCardNFTs",
  TOKENS: "userTokens",
  TOKEN_UPDATE: "userTokenUpdate",
};

export const USER_ACTIONS = {
  LOGIN_RECHECK: "userLoginRecheck",
  LOGIN_MAIAR: "userLoginMaiar",
  POST_LOGIN_MAIAR: "userPostLoginMaiar",
  LOGIN_EXTENSION: "userLoginExtension",
  LOGIN_LEDGER: "userLoginLedger",
  POST_LOGIN_LEDGER: "userPostLoginLedger",
  LOGIN_WEB_WALLET: "userLoginWebWallet",
  POST_LOGIN_WEB_WALLET: "userPostLoginWebWallet",
  LOGIN_HUB: "userLoginHub",
  LOGOUT_ELROND: "userLogoutElrond",

  BACKEND_GET_LOGIN_TOKEN: "userBackendGetLoginToken",

  RELOAD_ACCOUNT: "userReloadAccount",

  LOGIN_BACKEND: "userLoginBackend",

  GET_NFTS: "userGetNfts",
  GET_NEXT_GEN_NFTS: "userGetNextGenNFTs",
  GET_TOKEN_BALANCE: "userGetTokenBalance",
};

interface IUserStore {
  state: () => {
    addressElrond: string | boolean;
    accountElrond?: Account;
    nfts?: LIST<NFT_POST>;
    nextGenNfts?: LIST<NFT_POST>;
    cardNfts?: LIST<NFT_POST>;
    tokens: { [key: string]: TokenTransfer };
  };

  [index: string]: any;
}

export const userStore: IUserStore = {
  state: () => ({
    addressElrond: false,
    accountElrond: null,
    nfts: null,
    nextGenNfts: null,
    cardNfts: null,
    tokens: {},
  }),
  getters: {
    [USER_GETTERS.ADDRESS_ELROND](state) {
      return state.addressElrond;
    },
    [USER_GETTERS.ACCOUNT_ELROND](state) {
      return state.accountElrond;
    },
    [USER_GETTERS.NFTS](state) {
      return state.nfts;
    },
    [USER_GETTERS.NEXT_GEN_NFTS](state) {
      return state.nextGenNfts;
    },
    [USER_GETTERS.CARD_NFTS](state) {
      return state.cardNfts;
    },
    [USER_GETTERS.TOKENS](state) {
      return state.tokens;
    },
  },
  mutations: {
    [USER_MUTATIONS.ADDRESS_ELROND](state, address) {
      state.addressElrond = address;
    },
    [USER_MUTATIONS.ACCOUNT_ELROND](state, account) {
      state.accountElrond = account;
    },
    [USER_MUTATIONS.NFTS](state, nfts) {
      state.nfts = nfts;
    },
    [USER_MUTATIONS.NEXT_GEN_NFTS](state, nextGenNfts) {
      state.nextGenNfts = nextGenNfts;
    },
    [USER_MUTATIONS.CARD_NFTS](state, cardNfts) {
      state.cardNfts = cardNfts;
    },
    [USER_MUTATIONS.TOKENS](state, tokens) {
      state.tokens = tokens;
    },
    [USER_MUTATIONS.TOKEN_UPDATE](state, { token, balance }) {
      state.tokens[token] = balance;
    },
  },
  actions: {
    async [USER_ACTIONS.LOGIN_RECHECK]({ dispatch }) {
      const query = new URLSearchParams(window.location.search);

      if (query.has("accessToken")) {
        await dispatch(USER_ACTIONS.LOGIN_HUB, { accessToken: query.get("accessToken") });

        return;
      }

      // if (!storageHelper.getBackendAccessToken()) {
      //   dispatch(USER_ACTIONS.LOGOUT_ELROND);
      //
      //   return;
      // }

      let initialAddress = storageHelper.getWalletLogin();
      if (null !== initialAddress) {
        await dispatch(USER_ACTIONS.POST_LOGIN_WEB_WALLET, initialAddress);

        return;
      }

      // Check if there is an extension login address saved
      initialAddress = storageHelper.getExtensionLogin();

      if (initialAddress) {
        await dispatch(USER_ACTIONS.LOGIN_EXTENSION, initialAddress);

        return;
      }

      // Check if there is a Maiar login address saved
      initialAddress = storageHelper.getMaiarLogin();
      if (initialAddress) {
        await dispatch(USER_ACTIONS.LOGIN_MAIAR, {
          handleOnLogin: () => dispatch(USER_ACTIONS.POST_LOGIN_MAIAR, true),
          handleOnLogout: () => dispatch(USER_ACTIONS.LOGOUT_ELROND),
          isRecheck: true,
        });

        return;
      }

      initialAddress = storageHelper.getLedgerLogin();
      if (initialAddress !== null) {
        try {
          await dispatch(USER_ACTIONS.LOGIN_LEDGER, initialAddress);

          return;
        } catch (e) {
          console.error(e);
        }
      }

      dispatch(USER_ACTIONS.LOGOUT_ELROND);
    },

    async [USER_ACTIONS.LOGIN_MAIAR]({ dispatch }, { handleOnLogin, handleOnLogout, isRecheck = false }) {
      const initialized = await elrondHelper.initializeMaiar(handleOnLogin, handleOnLogout);

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert("Something went wrong...");

        return null;
      }

      if (isRecheck) {
        // const pairings = await (elrondHelper.provider as WalletConnectV2Provider).getPairings();
        //
        // console.log('pairings', pairings);

        return true;
      }

      const url = await elrondHelper.login("", "", false, false);
      const token = storageHelper.getBackendLoginToken();

      if (!token) {
        return url;
      }

      return url + "&token=" + token;
    },

    async [USER_ACTIONS.POST_LOGIN_MAIAR]({ commit, dispatch }, isRecheck = false) {
      const address = await (elrondHelper.provider as WalletConnectV2Provider).getAddress();

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert("Something went wrong...");

        return null;
      }

      // if (!isRecheck) {
      //   const signature = await (elrondHelper.provider as WalletConnectV2Provider).getSignature();
      //
      //   await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature });
      // }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setMaiarLogin(address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);

      return address;
    },

    async [USER_ACTIONS.LOGIN_EXTENSION]({ commit, dispatch }, initialAddress) {
      const initialized = await elrondHelper.initializeExtension(initialAddress);

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert("Extension not installed...");

        return null;
      }

      let address = initialAddress;
      if (!initialAddress) {
        try {
          address = await elrondHelper.login("", storageHelper.getBackendLoginToken());
        } catch (e) {
          // Error handled further down
        }
      }

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return null;
      }

      // if (!initialAddress) {
      //   const signature = (elrondHelper.provider as ExtensionProvider).account.signature;
      //
      //   await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature });
      // }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setExtensionLogin(address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);

      return address;
    },

    async [USER_ACTIONS.LOGIN_LEDGER]({ dispatch }, initialAddress = null) {
      const initialized = await elrondHelper.initializeLedger();

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return false;
      }

      if (initialAddress) {
        return await dispatch(USER_ACTIONS.POST_LOGIN_LEDGER, initialAddress);
      }

      return await (elrondHelper.provider as HWProvider).getAccounts();
    },
    async [USER_ACTIONS.POST_LOGIN_LEDGER](
      { dispatch, commit },
      { addressIndex, securityToken = null, address = null }
    ) {
      if (!address) {
        // @ts-ignore
        address = await elrondHelper.login("", securityToken, addressIndex);

        // if (address) {
        //   await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature: '' });
        // }
      } else {
        await elrondHelper.login("", "", addressIndex, true);
      }

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert("Something went wrong...");

        return null;
      }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setLedgerLogin(addressIndex, address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);

      return address;
    },
    async [USER_ACTIONS.LOGIN_WEB_WALLET](_, { redirect, type }) {
      await elrondHelper.initializeWebWallet(type);

      await storageHelper.setWalletLogin();
      await storageHelper.setWalletLoginType(type);

      await elrondHelper.login(redirect || PATHS_EN[ROUTES.home]);
    },
    async [USER_ACTIONS.POST_LOGIN_WEB_WALLET]({ commit, dispatch }, address) {
      const type = storageHelper.getWalletLoginType() || WALLET_LOGIN_TYPE.WEB_WALLET;
      await elrondHelper.initializeWebWallet(type);

      // Check if we are in callback from Web Wallet and get address from URL
      if (!address) {
        const query = new URLSearchParams(window.location.search);

        if (query.has("address")) {
          address = query.get("address");
        }

        // if (query.has("signature") && query.has("loginToken")) {
        //   const signature = query.get("signature");
        //
        //   await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature });
        //
        // }

        const newQuery = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        delete newQuery.address;
        delete newQuery.signature;
        // delete newQuery.loginToken;

        if (address) {
          try {
            const router = (await import("@/router")).default;
            router.replace({ path: window.location.pathname, query: newQuery });
          } catch (e) {
            // Do nothing
          }
        }
      }

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return null;
      }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setWalletLogin(address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);

      return address;
    },
    async [USER_ACTIONS.LOGIN_HUB]({ commit, dispatch }, { accessToken }) {
      const result = elrondHelper.decodeNativeAuthToken(accessToken);

      if (!result) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return null;
      }

      const initialized = await elrondHelper.initializeWebView(result, accessToken);

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return null;
      }

      // await elrondHelper.login();

      let address = result.address;

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return null;
      }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setWebViewLogin(address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);

      return address;
    },

    [USER_ACTIONS.LOGOUT_ELROND]({ commit, getters }) {
      elrondHelper.logout();

      storageHelper.clearLogins(true, getters[USER_GETTERS.ADDRESS_ELROND]);

      commit(USER_MUTATIONS.ADDRESS_ELROND, null);
      commit(USER_MUTATIONS.ACCOUNT_ELROND, null);
      commit(USER_MUTATIONS.TOKENS, {});

      commit(REFERRAL_MUTATIONS.RESET);

      // Need actual string here, because of circular references
      commit("resetTransactionToast");
    },

    async [USER_ACTIONS.BACKEND_GET_LOGIN_TOKEN]() {
      // const token = await authClient.authToken();
      //
      // storageHelper.setBackendLoginToken(token);
    },

    async [USER_ACTIONS.RELOAD_ACCOUNT]({ commit, getters }) {
      const account = await elrondHelper.getAccount(getters[USER_GETTERS.ADDRESS_ELROND]);

      if (getters[USER_GETTERS.ACCOUNT_ELROND]) {
        getters[USER_GETTERS.ACCOUNT_ELROND].update(account);

        return;
      }

      commit(USER_MUTATIONS.ACCOUNT_ELROND, account);
    },

    // async [USER_ACTIONS.LOGIN_BACKEND]({ commit, getters, dispatch }, { address, signature }) {
    // const token = storageHelper.getBackendLoginToken();
    //
    // if (!token) {
    //   await dispatch(USER_ACTIONS.LOGOUT_ELROND);
    //
    //   alert('Backend login failed...');
    //   throw new Error('Backend login token missing');
    // }
    //
    // try {
    //   const accessToken = await authClient.authLogin(address, token, signature);
    //
    //   storageHelper.setBackendAccessToken(accessToken);
    // } catch (e) {
    //   await dispatch(USER_ACTIONS.LOGOUT_ELROND);
    //
    //   alert('Backend login failed...');
    //   throw new Error('Backend login failed');
    // }
    // },

    async [USER_ACTIONS.GET_NFTS]({ commit, getters }, { page, collection, mutation, perPage = GENERAL.PER_PAGE }) {
      const address = getters[USER_GETTERS.ADDRESS_ELROND];

      const total = await elrondApiHelper.getAddressNftsCount(address, collection);
      const nfts = await elrondApiHelper.getAddressNfts(address, collection, page, total, perPage);

      if (0 === page) {
        storeHelper.commitList(commit, mutation, nfts, page, total);

        return;
      }

      const oldNfts = getters[mutation]?.items;
      const newNfts = [...oldNfts, ...nfts];

      storeHelper.commitList(commit, mutation, newNfts, page, total);
    },
    async [USER_ACTIONS.GET_TOKEN_BALANCE](
      { commit, dispatch, getters },
      { token, decimals = null, force = false, transaction = null }
    ) {
      if (!getters[USER_GETTERS.ACCOUNT_ELROND]) {
        return null;
      }

      if (token in getters[USER_GETTERS.TOKENS] && !force) {
        return getters[USER_GETTERS.TOKENS][token];
      }

      if (!decimals) {
        decimals = BLOCKCHAIN.TOKENS[token]?.decimals
      }

      if (transaction) {
        // TODO: Set token balance earlier here by calculating depending on transaction status?
        const transactionWatcher = new TransactionWatcher(elrondApiHelper.apiProvider, {
          timeoutMilliseconds: TransactionWatcher.DefaultTimeout * 2,
        });
        await transactionWatcher.awaitCompleted(transaction);
      }

      if (ELROND.EGLD_TOKEN === token) {
        if (force) {
          await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);
        }

        const balance = TokenTransfer.egldFromBigInteger(getters[USER_GETTERS.ACCOUNT_ELROND].balance);

        commit(USER_MUTATIONS.TOKEN_UPDATE, { token, balance });

        return balance;
      }

      try {
        const tokenBalance = await elrondApiHelper.getAccountTokenBalance(getters[USER_GETTERS.ADDRESS_ELROND], token);

        const balance = TokenTransfer.fungibleFromBigInteger(tokenBalance.identifier, tokenBalance.balance, decimals);

        commit(USER_MUTATIONS.TOKEN_UPDATE, { token, balance });

        return balance;
      } catch (e) {
        commit(USER_MUTATIONS.TOKEN_UPDATE, {
          token,
          balance: TokenTransfer.fungibleFromBigInteger(token, 0, decimals),
        });

        return null;
      }
    },
  },
};
