import ELROND from "@/constants/elrond";
import axios, { AxiosInstance } from "axios";

const PATHS = {
  PRICES: (tokenId: string) => `/prices/${tokenId}`,
};

class OneDexApi {
  client: AxiosInstance;

  cache: { [token: string]: string } = {};

  constructor() {
    this.client = axios.create({
      baseURL: ELROND.ONEDEX_API,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 30000,
    });
  }

  async getTokenPrice(tokenId: string): Promise<string> {
    if (this.cache[tokenId]) {
      return this.cache[tokenId];
    }

    try {
      const response = await this.client.get(PATHS.PRICES(tokenId));

      this.cache[tokenId] = String(response.data?.[0]?.usdPrice || 0);

      return this.cache[tokenId];
    } catch(e) {
      console.error(e);

      return '0';
    }
  }
}

const oneDexApi = new OneDexApi();

export default oneDexApi;
