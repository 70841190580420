import BaseContract from "@/contracts/baseContract";
import { BigNumber } from "bignumber.js";
import elrondHelper from "@/helpers/elrond";
import { Account, TokenTransfer, Transaction } from "@multiversx/sdk-core/out";

export const POOL_STAKING_FUNCTIONS = {
  STAKE: "stake",
  UNSTAKE: "unstake",
  CLAIM_REWARDS: "claim_rewards",
};

export class PoolStakingContract extends BaseContract {
  private static poolStakingContracts: { [address: string]: PoolStakingContract } = {};

  constructor(
    poolAddress: string,
    private readonly metaToken: string,
    private readonly token: string,
    private readonly tokenDecimals: number
  ) {
    super(poolAddress, "pool-staking", "StakingContract");
  }

  static getContract(
    poolAddress: string,
    metaToken: string,
    token: string,
    tokenDecimals: number
  ): PoolStakingContract {
    if (!(poolAddress in PoolStakingContract.poolStakingContracts)) {
      PoolStakingContract.poolStakingContracts[poolAddress] = new PoolStakingContract(
        poolAddress,
        metaToken,
        token,
        tokenDecimals
      );
    }

    return PoolStakingContract.poolStakingContracts[poolAddress];
  }

  // Endpoints
  async stake(
    account: Account,
    amount: BigNumber.Value,
    positionAmount: BigNumber | null = null,
    positionNonce: number | null = null,
    boostTokenCollection: string | null = null,
    boostTokenAmount: BigNumber | null = null,
    boostTokenNonce: number | null = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[POOL_STAKING_FUNCTIONS.STAKE]([]).withGasLimit(15000000);

    const extraTokens: TokenTransfer[] = [];

    if (positionAmount && positionNonce) {
      extraTokens.push(TokenTransfer.metaEsdtFromBigInteger(this.metaToken, positionNonce, positionAmount));
    }

    if (boostTokenAmount && boostTokenNonce) {
      extraTokens.push(TokenTransfer.metaEsdtFromBigInteger(boostTokenCollection, boostTokenNonce, boostTokenAmount));
    }

    if (extraTokens.length) {
      interaction.withMultiESDTNFTTransfer([
        TokenTransfer.fungibleFromAmount(this.token, amount, this.tokenDecimals),
        ...extraTokens,
      ]);
    } else {
      interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromAmount(this.token, amount, this.tokenDecimals));
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async unstake(account: Account, amount: BigNumber.Value, positionNonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[POOL_STAKING_FUNCTIONS.UNSTAKE]([]).withGasLimit(15000000);

    // Meta token has same number of decimals as token
    interaction.withSingleESDTNFTTransfer(
      TokenTransfer.metaEsdtFromAmount(this.metaToken, positionNonce, amount, this.tokenDecimals)
    );

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async claimRewards(
    account: Account,
    positionAmount: BigNumber,
    positionNonce: number,
    boostTokenCollection: string | null = null,
    boostTokenAmount: BigNumber | null = null,
    boostTokenNonce: number | null = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[POOL_STAKING_FUNCTIONS.CLAIM_REWARDS]([]).withGasLimit(15000000);

    if (boostTokenAmount && boostTokenNonce) {
      interaction.withMultiESDTNFTTransfer([
        TokenTransfer.metaEsdtFromBigInteger(this.metaToken, positionNonce, positionAmount),
        TokenTransfer.metaEsdtFromBigInteger(boostTokenCollection, boostTokenNonce, boostTokenAmount),
      ]);
    } else {
      interaction.withSingleESDTNFTTransfer(
        TokenTransfer.metaEsdtFromBigInteger(this.metaToken, positionNonce, positionAmount)
      );
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }
}
