import BLOCKCHAIN from "@/constants/blockchain";
import BaseContract from "@/contracts/baseContract";
import {
  Account,
  BigUIntValue,
  ContractFunction,
  Interaction,
  Struct,
  TokenTransfer,
  Transaction,
  Tuple,
  VariadicValue,
} from "@multiversx/sdk-core";
import elrondHelper from "@/helpers/elrond";
import { BigNumber } from "bignumber.js";

export const NUTS_STAKING_CONSTANTS = {
  MINIMUM_UNSTAKE_TIME: 14_400, // 1 day
};

export const NUTS_STAKING_FUNCTIONS = {
  STAKE: "stake",
  UNSTAKE: "unstake",
  CLAIM_REWARDS: "claim_rewards",
  COMPOUND_REWARDS: "compound_rewards",
};

export const LOCKING_PERIODS = [
  432000, // 30 days
  1296000, // 90 days
  2592000, // 180 days
  5184000, // 360 days
];

class NutsStakingContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.CONTRACTS.NUTS_STAKING, "nuts-staking", "StakingContract");
  }

  // Endpoints
  async stake(
    account: Account,
    lockingPeriod: number,
    amount: BigNumber.Value,
    positionAmount: BigNumber | null = null,
    positionNonce: number | null = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NUTS_STAKING_FUNCTIONS.STAKE]([lockingPeriod]).withGasLimit(12000000); // 7,000,000 from Mandos + 5,000,000 just in case

    if (positionAmount && positionNonce) {
      interaction.withMultiESDTNFTTransfer([
        TokenTransfer.fungibleFromAmount(
          BLOCKCHAIN.TOKEN_IDENTIFIER,
          amount,
          BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
        ),
        TokenTransfer.metaEsdtFromBigInteger(
          BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER,
          positionNonce,
          positionAmount,
          BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
        ),
      ]);
    } else {
      interaction.withSingleESDTTransfer(
        TokenTransfer.fungibleFromAmount(
          BLOCKCHAIN.TOKEN_IDENTIFIER,
          amount,
          BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
        )
      );
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async unstake(account: Account, amount: BigNumber, nonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NUTS_STAKING_FUNCTIONS.UNSTAKE]([]).withGasLimit(13000000); // 8,000,000 from Mandos + 5,000,000 just in case

    // Use NUTS decimals here since Meta token has 18 decimals
    interaction.withSingleESDTNFTTransfer(
      TokenTransfer.metaEsdtFromBigInteger(
        BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER,
        nonce,
        amount,
        BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
      )
    );

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async claimRewards(account: Account, amount: BigNumber, nonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NUTS_STAKING_FUNCTIONS.CLAIM_REWARDS]([]).withGasLimit(13000000); // 8,000,000 from Mandos + 5,000,000 just in case

    interaction.withSingleESDTNFTTransfer(
      TokenTransfer.metaEsdtFromBigInteger(
        BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER,
        nonce,
        amount,
        BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
      )
    );

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async compoundRewards(account: Account, amount: BigNumber, nonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NUTS_STAKING_FUNCTIONS.COMPOUND_REWARDS]([]).withGasLimit(13000000); // 8,000,000 from Mandos + 5,000,000 just in case

    interaction.withSingleESDTNFTTransfer(
      TokenTransfer.metaEsdtFromBigInteger(
        BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER,
        nonce,
        amount,
        BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
      )
    );

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  // Views
  async calculateMultipleRewards(positions: { amount: BigNumber; attributes: Struct }[]): Promise<TokenTransfer[]> {
    if (!positions.length) {
      return [];
    }

    await this.getContractAbi();

    const interaction = <Interaction>(
      this.contract.methodsExplicit.calculate_rewards_for_multiple_positions([
        VariadicValue.fromItems(
          ...positions.map((position) => Tuple.fromItems([new BigUIntValue(position.amount), position.attributes]))
        ),
      ])
    );
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return firstValue
      .valueOf()
      .map((value) =>
        TokenTransfer.fungibleFromBigInteger(
          BLOCKCHAIN.TOKEN_IDENTIFIER,
          value.valueOf(),
          BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
        )
      );
  }

  async calculateReward(amount: BigNumber, position: Struct): Promise<TokenTransfer> {
    await this.getContractAbi();

    const query = await this.contract.createQuery({
      func: new ContractFunction("calculate_rewards_for_given_position"),
      args: [new BigUIntValue(amount), position],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("calculate_rewards_for_given_position");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return TokenTransfer.fungibleFromBigInteger(
      BLOCKCHAIN.TOKEN_IDENTIFIER,
      firstValue.valueOf(),
      BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
    );
  }

  async getFarmTokenSupplies(): Promise<{ lockingPeriod: number; supply: BigNumber }[]> {
    await this.getContractAbi();

    const query = await this.contract.createQuery({
      func: new ContractFunction("getFarmTokenSupplies"),
      args: [],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("getFarmTokenSupplies");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    const values: { field0: number; field1: BigNumber }[] = firstValue.valueOf();

    return values.map((value) => ({
      lockingPeriod: value.field0,
      supply: value.field1,
    }));
  }
}

const nutsStakingContract = new NutsStakingContract();

export default nutsStakingContract;
