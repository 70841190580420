import BaseContract from "@/contracts/baseContract";
import { Account, TokenTransfer, Transaction } from "@multiversx/sdk-core/out";
import ELROND from "@/constants/elrond";
import elrondHelper from "@/helpers/elrond";

export const NFT_UPGRADE_FUNCTIONS = {
  UPGRADE_NFT: "upgrade_nft",
};

export class NftUpgradeContract extends BaseContract {
  private static upgradeContracts: { [address: string]: NftUpgradeContract } = {};

  constructor(address: string, private readonly nftTokenId: string, private readonly upgradeNftTokenId: string) {
    super(address, "nft-upgrade", "NftUpgrade");
  }

  static getContract(address: string, nftTokenId: string, upgradeNftTokenId: string): NftUpgradeContract {
    if (!(address in NftUpgradeContract.upgradeContracts)) {
      NftUpgradeContract.upgradeContracts[address] = new NftUpgradeContract(address, nftTokenId, upgradeNftTokenId);
    }

    return NftUpgradeContract.upgradeContracts[address];
  }

  // Endpoints
  async upgrade(account: Account, nonces: number[]): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NFT_UPGRADE_FUNCTIONS.UPGRADE_NFT]([]).withGasLimit(
      Math.min(10000000 + 2000000 * nonces.length, ELROND.MAX_GAS)
    );

    const payments = nonces.map((nonce) => TokenTransfer.nonFungible(this.nftTokenId, nonce));

    interaction.withMultiESDTNFTTransfer(payments);

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }
}
