import { Account, Address, Interaction, TokenTransfer, Transaction, U16Value } from "@multiversx/sdk-core";
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import ELROND from "@/constants/elrond";
import { BigNumber } from "bignumber.js";
import BaseContract from "@/contracts/baseContract";
import { U32Value } from "@multiversx/sdk-core/out";

export const GAMES_PROXY_FUNCTIONS = {
  TOKEN_FLIP: "token_flip",
  BLACKJACK: "blackjack",
  HIDE_AND_SEEK: "hide_and_seek",
  RPS: "rps",
  ONE_VS_ONE: "one_vs_one",
  NUTS_ROULETTE: "nuts_roulette",
  ENTER_RAFFLE: "enter_raffle",
};

export const ZERO_ADDRESS = "erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu";

class GamesProxyContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.CONTRACTS.GAMES_PROXY, "games-proxy", "GamesProxy");
  }

  async tokenFlip(
    account: Account,
    token: string,
    amount: number,
    decimals: number,
    times: number,
    referer: string = null,
    goNutsMin: BigNumber = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const args: any = [times, referer];
    if (goNutsMin) {
      args.push(goNutsMin);
    }

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.TOKEN_FLIP](args).withGasLimit(
      17_000_000 + 1_000_000 * times + (goNutsMin ? 15_000_000 : 0)
    );

    if (ELROND.EGLD_TOKEN === token) {
      interaction.withValue(TokenTransfer.egldFromAmount(amount));
    } else {
      interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromAmount(token, amount, decimals));
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async blackjack(
    account: Account,
    token: string | null,
    amount: number | null,
    decimals: number,
    stand: boolean = false,
    referer: string = null,
    goNutsMin: BigNumber = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const args: any = [stand, referer];
    if (goNutsMin) {
      args.push(goNutsMin);
    }

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.BLACKJACK](args).withGasLimit(
      20_000_000 + (goNutsMin ? 15_000_000 : 0)
    );

    if (token && amount) {
      if (ELROND.EGLD_TOKEN === token) {
        interaction.withValue(TokenTransfer.egldFromAmount(amount));
      } else {
        interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromAmount(token, amount, decimals));
      }
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async hideAndSeek(
    account: Account,
    collection: string,
    gameMode: number,
    selectedCard: number,
    token: string,
    amount: number,
    decimals: number,
    times: number,
    referer: string = null,
    goNutsMin: BigNumber = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const args: any = [collection, gameMode, selectedCard, times, referer];
    if (goNutsMin) {
      args.push(goNutsMin);
    }

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.HIDE_AND_SEEK](args).withGasLimit(
      17_000_000 + 1_000_000 * times + (goNutsMin ? 15_000_000 : 0)
    );

    if (ELROND.EGLD_TOKEN === token) {
      interaction.withValue(TokenTransfer.egldFromAmount(amount));
    } else {
      interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromAmount(token, amount, decimals));
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async rps(
    account: Account,
    collection: string,
    selectedCard: number,
    token: string,
    amount: number,
    decimals: number,
    referer: string = null,
    goNutsMin: BigNumber = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const args: any = [collection, selectedCard, referer];
    if (goNutsMin) {
      args.push(goNutsMin);
    }

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.RPS](args).withGasLimit(
      19_000_000 + (goNutsMin ? 15_000_000 : 0)
    );

    if (ELROND.EGLD_TOKEN === token) {
      interaction.withValue(TokenTransfer.egldFromAmount(amount));
    } else {
      interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromAmount(token, amount, decimals));
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async oneVsOne(
    account: Account,
    id: number,
    collection: string,
    token: string,
    amount: BigNumber,
    referer: string = null
  ): Promise<Transaction> {
    await this.getContractAbi();

    const args = [id, collection, referer];

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.ONE_VS_ONE](args).withGasLimit(23_000_000);

    if (ELROND.EGLD_TOKEN === token) {
      interaction.withValue(TokenTransfer.egldFromBigInteger(amount));
    } else {
      interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromBigInteger(token, amount));
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async nutsRoulette(
    account: Account,
    nbBullets: number,
    nutsAmount: BigNumber,
    wegldAmount: BigNumber,
    nft: TokenTransfer = null,
    referer: string = null,
    goNuts: boolean = false
  ): Promise<Transaction> {
    await this.getContractAbi();

    const args: any = [nbBullets, referer];
    if (goNuts) {
      args.push(nutsAmount);
    }

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.NUTS_ROULETTE](args)
      .withGasLimit(
        21_000_000 + 1_000_000 * nbBullets + (goNuts ? 15_000_000 : 0)
      )
      .withMultiESDTNFTTransfer([
        ...(!goNuts ? [TokenTransfer.fungibleFromBigInteger(BLOCKCHAIN.TOKEN_IDENTIFIER, nutsAmount)] : []),
        TokenTransfer.fungibleFromBigInteger(BLOCKCHAIN.TOKEN_IDENTIFIER_WEGLD, wegldAmount),
        ...(nft ? [nft] : []),
      ])
      .withSender(account.address);

    return interaction.buildTransaction();
  }

  async enterRaffle(account: Account, nbTickets: number, token: string, amount: BigNumber): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[GAMES_PROXY_FUNCTIONS.ENTER_RAFFLE]([nbTickets]).withGasLimit(13000000); // 10,000,000 from Mandos + 3,000,000 just in case

    interaction.withSingleESDTTransfer(TokenTransfer.fungibleFromBigInteger(token, amount));

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async getReferer(account: Account): Promise<string | null> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.referer([account.address]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    if (!response.returnData.length) {
      return null;
    }

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return (firstValue.valueOf() as Address).bech32();
  }

  async getNumberOfReferals(address: string): Promise<number> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.number_of_referals([address]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    if (!response.returnData.length) {
      return 0;
    }

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return Number.parseInt((firstValue.valueOf() as U16Value).toString());
  }

  async getRaffleTicketsNb(): Promise<number> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.raffle_tickets_nb([]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    if (!response.returnData.length) {
      return 0;
    }

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return Number.parseInt((firstValue.valueOf() as U32Value).toString());
  }
}

const gamesProxyContract = new GamesProxyContract();

export default gamesProxyContract;
