import elrondHelper from "@/helpers/elrond";
import { Address, ContractFunction, Interaction, SmartContract, Transaction } from "@multiversx/sdk-core/out";
import ENV from "@/constants/env";

class WegldSwapContract {
  wrapEgld(address: string, amount: string): Transaction {
    const shard = elrondHelper.getAddressShard(address);

    const contract = new SmartContract({ address: Address.fromBech32(ENV.CONTRACTS_WEGLD_WRAP[shard]) });
    const interaction = new Interaction(contract, new ContractFunction("wrapEgld"), []);

    return interaction.withValue(amount).withGasLimit(5_000_000).buildTransaction();
  }
}

const wegldSwapContract = new WegldSwapContract();

export default wegldSwapContract;
