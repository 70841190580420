import BLOCKCHAIN from "@/constants/blockchain";
import BaseContract from "@/contracts/baseContract";
import { Account, Interaction, Struct, TokenTransfer } from "@multiversx/sdk-core";
import elrondHelper from "@/helpers/elrond";

export const TOKEN_FLIP_FUNCTIONS = {
  PLAY: "play_times",
  PLAY_BLACKJACK: "play_blackjack",
};

export const BLACKJACK_CONSTANTS = {
  CARDS_IN_DECK: 52, // 13 * 4
  DISTINCT_CARDS_IN_DECK: 13, // A, 2-10, J, Q, K
  TOTAL_BLACKJACK: 21,
  WON_NORMAL_MULTIPLIER: 2,
  WON_BLACKJACK_MULTIPLIER: 2.5,
  STATUS: {
    IN_PROGRESS: -1,
    LOSE: 0,
    WIN: 1,
    STANDOFF: 2,
  },
};

export interface Game {
  amount: TokenTransfer;
  cards: number[];
  cardsTotal: number;
  acesSubstracted: number;
  dealerCards: number[];
  status: number; // -1 - game in progress, 0 - lose, 1 - win, 2 - standoff
}

class TokenFlipContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.CONTRACTS.TOKEN_FLIP, "token-flip", "TokenFlip");
  }

  async getGame(account: Account): Promise<Game> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.games([account.address]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    if (!response.returnData.length) {
      return null;
    }

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return this.parseGame(firstValue as Struct);
  }

  parseGame(value: Struct): Game {
    const token = value.getFieldValue("token");
    const cardsTotal = value.getFieldValue("cards_total").toNumber();
    const dealerCards = Array.from(value.getFieldValue("dealer_cards") as Uint8Array);

    return {
      amount: TokenTransfer.fungibleFromBigInteger(
        token,
        value.getFieldValue("amount"),
        BLOCKCHAIN.TOKENS[token]?.decimals || 18
      ),
      cards: Array.from(value.getFieldValue("cards") as Uint8Array),
      cardsTotal,
      acesSubstracted: value.getFieldValue("aces_substracted"),
      dealerCards,
      status: this.checkWin(cardsTotal, dealerCards),
    };
  }

  checkWin(cardsTotal: number, dealerCards: number[]) {
    // Won with 21
    if (1 === dealerCards.length && BLACKJACK_CONSTANTS.TOTAL_BLACKJACK === cardsTotal) {
      return BLACKJACK_CONSTANTS.STATUS.WIN;
    }

    // Player LOSE...
    if (cardsTotal > BLACKJACK_CONSTANTS.TOTAL_BLACKJACK) {
      return BLACKJACK_CONSTANTS.STATUS.LOSE;
    }

    // Game still ongoing
    if (1 === dealerCards.length && BLACKJACK_CONSTANTS.TOTAL_BLACKJACK !== cardsTotal) {
      return BLACKJACK_CONSTANTS.STATUS.IN_PROGRESS;
    }

    const dealerTotal = this.calculateDealerScore(dealerCards);

    // Player WIN!
    if (dealerTotal > BLACKJACK_CONSTANTS.TOTAL_BLACKJACK || cardsTotal > dealerTotal) {
      return BLACKJACK_CONSTANTS.STATUS.WIN;
    }

    // Player STANDOFF...
    if (cardsTotal == dealerTotal) {
      return BLACKJACK_CONSTANTS.STATUS.STANDOFF;
    }

    // Player LOSE...
    return BLACKJACK_CONSTANTS.STATUS.LOSE;
  }

  calculateDealerScore(cards: number[]) {
    const totalizer = (total, c) => total + this.getCardValue(c);

    let total = cards.reduce(totalizer, 0);

    if (total <= BLACKJACK_CONSTANTS.TOTAL_BLACKJACK) {
      return total;
    }

    for (const card of cards) {
      if (11 === this.getCardValue(card)) {
        total -= 10;
      }

      if (total <= BLACKJACK_CONSTANTS.TOTAL_BLACKJACK) {
        break;
      }
    }

    return total;
  }

  getCardValue(card) {
    let value = card % BLACKJACK_CONSTANTS.DISTINCT_CARDS_IN_DECK;

    // J, Q, K are counted as 10
    if (value > 10 || value === 0) {
      value = 10;
    } else if (value === 1) {
      // A is initially counted as 11
      value = 11;
    }

    return value;
  }
}

const tokenFlipContract = new TokenFlipContract();

export default tokenFlipContract;
