import hideAndSeekContract, { HIDE_AND_SEEK_FUNCTIONS } from "@/contracts/hideAndSeek";
import BLOCKCHAIN from "@/constants/blockchain";
import elrondApiHelper from "@/helpers/elrondApi";
import { BytesValue, TokenTransfer } from "@multiversx/sdk-core";
import gamesProxyContract, { GAMES_PROXY_FUNCTIONS } from "@/contracts/gamesProxy";
import elrondHelper from "@/helpers/elrond";
import { REFERRAL_GETTERS } from "@/store/referral";
import { TransactionOnNetwork } from "@multiversx/sdk-network-providers/out";
import { TransactionDecoder } from "@multiversx/sdk-transaction-decoder/lib/src/transaction.decoder";
import { NumericalValue } from "@multiversx/sdk-core/out";
import { BigNumber } from "bignumber.js";
import ELROND from "@/constants/elrond";

export const HIDE_AND_SEEK_GETTERS = {
  RECENT_GAMES: "hideAndSeekRecentGames",
};

export const HIDE_AND_SEEK_MUTATIONS = {
  RECENT_GAMES: "hideAndSeekRecentGames",
};

export const HIDE_AND_SEEK_ACTIONS = {
  PLAY: "hideAndSeekPlay",
  GET_RECENT_GAMES: "hideAndSeekGetRecentGames",
};

interface RECENT_GAME {
  index: number;
  hash: string;
  address: string;
  tokenTransfer: TokenTransfer;
  timesWon: number;
  timesLost: number;
  mode: number;
  goNuts: boolean;
}

interface IHideAndSeekStore {
  state: () => {
    recentGames: RECENT_GAME[];
  };

  [index: string]: any;
}

export const hideAndSeekStore: IHideAndSeekStore = {
  state: () => ({
    recentGames: [],
  }),
  getters: {
    [HIDE_AND_SEEK_GETTERS.RECENT_GAMES](state) {
      return state.recentGames;
    },
  },
  mutations: {
    [HIDE_AND_SEEK_MUTATIONS.RECENT_GAMES](state, recentGames) {
      state.recentGames = recentGames;
    },
  },
  actions: {
    async [HIDE_AND_SEEK_ACTIONS.PLAY](
      { getters },
      { accountElrond, collection, gameMode, selectedCard, token, amount, times, goNutsPricePerEgld }
    ) {
      const extraSlippage = amount > BLOCKCHAIN.GAME_BET[ELROND.EGLD_TOKEN][2] ? 0.03 : 0;

      return await gamesProxyContract.hideAndSeek(
        accountElrond,
        collection,
        gameMode,
        selectedCard,
        token,
        amount * times,
        BLOCKCHAIN.TOKENS[token].decimals,
        times,
        getters[REFERRAL_GETTERS.REFERER],
        goNutsPricePerEgld
          ? goNutsPricePerEgld
            .multipliedBy(0.99 - (times * 0.01) - extraSlippage) // 1% slippage + 1% additional per times
            .multipliedBy(amount * times)
            .decimalPlaces(0)
          : null
      );
    },
    async [HIDE_AND_SEEK_ACTIONS.GET_RECENT_GAMES]({ commit, getters }) {
      if (getters[HIDE_AND_SEEK_GETTERS.RECENT_GAMES].length) {
        return;
      }

      const response = await elrondApiHelper.getTransactionsToProxy(GAMES_PROXY_FUNCTIONS.HIDE_AND_SEEK);

      await hideAndSeekContract.getContractAbi();

      const decoder = new TransactionDecoder();

      let index = 0;

      const recentGames: RECENT_GAME[] = response
        .map((transaction: TransactionOnNetwork) => {
          try {
            const results = elrondHelper.parseCustomResults(
              transaction,
              hideAndSeekContract.contract.getEndpoint(HIDE_AND_SEEK_FUNCTIONS.PLAY)
            );

            if (!results) {
              return null;
            }

            const metadata = decoder.getTransactionMetadata({
              sender: transaction.sender.bech32(),
              receiver: transaction.receiver.bech32(),
              data: transaction.data.toString("base64"),
              value: transaction.value.toString(),
            });

            const { firstValue, secondValue } = results;
            const timesWon = (firstValue as NumericalValue).value.toNumber();
            const timesLost = (secondValue as NumericalValue).value.toNumber();
            const timesTotal = timesWon + timesLost;

            const tokenTransfer = !metadata.transfers
              ? TokenTransfer.egldFromBigInteger(new BigNumber(transaction.value).dividedToIntegerBy(new BigNumber(timesTotal)))
              : TokenTransfer.fungibleFromBigInteger(
                metadata.transfers[0].properties.identifier,
                new BigNumber(metadata.transfers[0].value.toString()).dividedToIntegerBy(new BigNumber(timesTotal)),
                BLOCKCHAIN.TOKENS[metadata.transfers[0].properties.identifier]?.decimals || 18
              );

            index++;

            return {
              index,
              hash: transaction.hash,
              address: transaction.sender.bech32(),
              tokenTransfer,
              timesWon,
              timesLost,
              mode: Number.parseInt(metadata.functionArgs[1], 16),
              goNuts: !!metadata.functionArgs?.[5],
            };
          } catch (e) {
            return null;
          }
        })
        .filter((value) => null !== value);

      commit(HIDE_AND_SEEK_MUTATIONS.RECENT_GAMES, recentGames);
    },
  },
};
