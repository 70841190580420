import ENV from "@/constants/env";
import {
  WALLET_PROVIDER_DEVNET,
  WALLET_PROVIDER_MAINNET,
  WALLET_PROVIDER_TESTNET,
} from "@multiversx/sdk-web-wallet-provider/out";

const ELROND_PER_ENV = {
  DEVNET: {
    GATEWAY: "https://devnet-gateway.multiversx.com",
    API: "https://devnet-api.multiversx.com",
    EXPLORER: "https://devnet-explorer.multiversx.com",
    WALLET_PROVIDER: WALLET_PROVIDER_DEVNET,
    XALIAS_PROVIDER: "https://devnet.xalias.com",
    XOXNO_URL: "https://dev.xoxno.com",

    XOXNO_API: "https://devnet-api.xoxno.com",
    ONEDEX_API: "https://devnet-api.onedex.app",
  },

  MAINNET: {
    GATEWAY: "https://elrond-mainnet.blastapi.io/f2ebfea7-1dd5-43c2-9fa4-ce33284d425a",
    // API: 'https://elrond-api.blastapi.io/f2ebfea7-1dd5-43c2-9fa4-ce33284d425a',
    API: "https://api.multiversx.com",
    EXPLORER: "https://explorer.multiversx.com",
    WALLET_PROVIDER: WALLET_PROVIDER_MAINNET,
    XALIAS_PROVIDER: "https://xalias.com",
    XOXNO_URL: "https://xoxno.com",

    XOXNO_API: "https://api.xoxno.com",
    ONEDEX_API: "https://api.onedex.app",
  },
};

const ELROND = {
  WALLET_CONNECT_WS: "wss://relay.walletconnect.com",
  WALLET_CONNECT_PROJECT_ID: "d5058af23d6ac59aaa12592d49a50763",
  WALLET_CONNECT_DEEP_LINK:
    "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/",
  EGLD_TOKEN: "EGLD",
  EGLD_DENOMINATOR: "1000000000000000000", // 1 EGLD
  WEGLD_TOKEN: "WEGLD",
  EMPTY_ADDRESS: "erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu",

  BLOCKS_PER_DAY: 14400,
  TIME_PER_BLOCK: 6, // 6 seconds per block

  TOKEN_LOGO: `https://media.elrond.com/tokens/asset/{TOKEN}/logo.svg`,

  USDC_TOKEN: "USDC",

  MAX_GAS: 600_000_000,

  ...ELROND_PER_ENV[ENV.ELROND_NET],
};

export default ELROND;
