
import { ROUTES } from "@/router/constants";
import formatterHelper from "@/helpers/formatter";
import { useStore } from "vuex";
import { computed } from "vue";
import { USER_ACTIONS, USER_GETTERS } from "@/store/user";
import ELROND from "@/constants/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import { useRoute } from "vue-router";
import TokenImage from '@/components/TokenImage.vue';

export default {
  name: "SidebarAccount",
  components: { TokenImage },
  created() {
    this.ROUTES = ROUTES;
    this.formatterHelper = formatterHelper;
    this.EXPLORER_URL = ELROND.EXPLORER + "/accounts/";
    this.BLOCKCHAIN = BLOCKCHAIN;
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const tokens = computed(() => {
      const newTokens = { ...store.getters[USER_GETTERS.TOKENS] };

      delete newTokens[ELROND.EGLD_TOKEN];

      return newTokens;
    });

    const getTokenImgUrl = (label) => {
      return ELROND.TOKEN_LOGO.replace("{TOKEN}", BLOCKCHAIN.TOKENS[label]?.logo || label);
    };

    return {
      route,
      address: computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]),
      accountElrond: computed(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]),
      userLogoutElrond: () => store.dispatch(USER_ACTIONS.LOGOUT_ELROND),
      tokens,
      getTokenImgUrl,
    };
  },
};
