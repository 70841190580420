const tokenIdentifiers = process.env.VUE_APP_TOKEN_IDENTIFIER.split(':');
const wegldIdentifiers = process.env.VUE_APP_TOKEN_IDENTIFIER_WEGLD.split(':');

const ENV = {
  ELROND_NET: process.env.VUE_APP_ELROND_NET,

  NFT_TOKEN_IDENTIFIER: process.env.VUE_APP_NFT_TOKEN_IDENTIFIER,
  NEXT_GEN_NFT_TOKEN_IDENTIFIER: process.env.VUE_APP_NEXT_GEN_NFT_TOKEN_IDENTIFIER,
  TOKEN_IDENTIFIER: tokenIdentifiers[0],
  TOKEN_IDENTIFIER_MAINNET: tokenIdentifiers[1],
  TOKEN_IDENTIFIER_WEGLD: wegldIdentifiers[0],
  TOKEN_IDENTIFIER_WEGLD_MAINNET: wegldIdentifiers[1],

  META_STAKING_TOKEN_IDENTIFIER: process.env.VUE_APP_META_STAKING_TOKEN_IDENTIFIER,
  STAKING_TOKEN_PER_BLOCK: process.env.VUE_APP_STAKING_TOKEN_PER_BLOCK,
  STAKING_MAX_APR: process.env.VUE_APP_STAKING_MAX_APR,

  CONTRACT_TOKEN_FLIP: process.env.VUE_APP_CONTRACT_TOKEN_FLIP,
  CONTRACT_HIDE_AND_SEEK: process.env.VUE_APP_CONTRACT_HIDE_AND_SEEK,
  CONTRACT_ONE_VS_ONE: process.env.VUE_APP_CONTRACT_ONE_VS_ONE,
  CONTRACT_GAMES_PROXY: process.env.VUE_APP_CONTRACT_GAMES_PROXY,
  CONTRACT_NUTS_STAKING: process.env.VUE_APP_CONTRACT_NUTS_STAKING,
  CONTRACT_NUTS_ROULETTE: process.env.VUE_APP_CONTRACT_NUTS_ROULETTE,

  CONTRACT_ONEDEX: process.env.VUE_APP_CONTRACT_ONEDEX,
  CONTRACTS_WEGLD_WRAP: process.env.VUE_APP_WEGLD_WRAP_CONTRACTS.split(';'),

  COLLECTIONS: process.env.VUE_APP_COLLECTIONS,
  TOKENS: process.env.VUE_APP_TOKENS,

  REWARDS_ADDRESS: process.env.VUE_APP_REWARDS_ADDRESS,

  CARD_NFT_TOKEN_IDENTIFIER: process.env.VUE_APP_CARD_NFT_TOKEN_IDENTIFIER,

  NUTS_PER_TICKET: process.env.VUE_APP_NUTS_PER_TICKET,

  MICROSERVICE_URL: process.env.VUE_APP_MICROSERVICE_URL,

  NFT_POOLS: process.env.VUE_APP_NFT_POOLS,

  IS_DEVELOPMENT: process.env.NODE_ENV === 'development',
};

export default ENV;