
import { useStore } from "vuex";
import { BASE_GETTERS, BASE_MUTATIONS } from "@/store";
import { computed } from "vue";
import GENERAL from "@/constants/general";

import '@/assets/css/toggler.scss';

export default {
  created() {
    this.THEMES = GENERAL.THEMES;
  },
  setup() {
    const store = useStore();

    const theme = computed(() => store.getters[BASE_GETTERS.THEME]);

    const toggleTheme = () => {
      store.commit(BASE_MUTATIONS.TOGGLE_THEME);
    };

    return {
      theme,
      toggleTheme,
    }
  }
}
