import { createStore } from "vuex";

import { userStore } from "./user";
import { hideAndSeekStore } from "@/store/hideAndSeek";
import { rpsStore } from "@/store/rps";
import { oneVsOneStore } from "@/store/oneVsOne";
import GENERAL from "@/constants/general";
import elrondApiHelper from "@/helpers/elrondApi";
import BLOCKCHAIN from "@/constants/blockchain";
import { BigNumber } from "bignumber.js";
import { tokenFlipStore } from "@/store/tokenFlip";
import { referralStore } from "@/store/referral";
import { stakingStore } from "@/store/staking";
import { blackjackStore } from "@/store/blackjack";
import { nftStakingStore } from "@/store/nftStaking";
import { poolsStakingStore } from '@/store/poolsStaking';
import { nutsRouletteStore } from "@/store/nutsRoulette";
import ELROND from "@/constants/elrond";

export const BASE_GETTERS = {
  MESSAGE_MODAL: "messageModal",
  CONFIRM_MODAL: "confirmModal",
  TRANSACTION_TOAST: "transactionToast",
  SIGN_TRANSACTION_TOAST: "signTransactionToast",
  THEME: "theme",
  REWARDS_BALANCE: "rewardsBalance",
};

export const BASE_MUTATIONS = {
  MESSAGE_MODAL: 'messageModal',
  CLOSE_MESSAGE_MODAL: 'closeMessageModal',
  CONFIRM_MODAL: 'confirmModal',
  CLOSE_CONFIRM_MODAL: 'closeConfirmModal',
  TRANSACTION_TOAST: 'transactionToast',
  REMOVE_TRANSACTION_TOAST: 'removeTransactionToast',
  RESET_TRANSACTION_TOAST: 'resetTransactionToast',
  SIGN_TRANSACTION_TOAST: 'signTransactionToast',
  TOGGLE_THEME: 'toggleTheme',
  REWARDS_BALANCE: 'rewardsBalance',
};

export const BASE_ACTIONS = {
  GET_REWARDS_BALANCE: 'getRewardsBalance',
};

const store = createStore({
  state: () => ({
    messageModal: null,
    confirmModal: null,
    transactionToast: {},
    signTransactionsToast: null,
    theme: localStorage.getItem(GENERAL.STORAGE.THEME) || GENERAL.THEMES.DARK,
    rewardsBalance: null,
  }),
  getters: {
    [BASE_GETTERS.MESSAGE_MODAL](state) {
      return state.messageModal;
    },
    [BASE_GETTERS.CONFIRM_MODAL](state) {
      return state.confirmModal;
    },
    [BASE_GETTERS.TRANSACTION_TOAST](state) {
      return state.transactionToast;
    },
    [BASE_GETTERS.SIGN_TRANSACTION_TOAST](state) {
      return state.signTransactionsToast;
    },
    [BASE_GETTERS.THEME](state) {
      return state.theme;
    },
    [BASE_GETTERS.REWARDS_BALANCE](state) {
      return state.rewardsBalance;
    },
  },
  mutations: {
    [BASE_MUTATIONS.MESSAGE_MODAL](state, messageModal) {
      state.messageModal = messageModal;
    },
    [BASE_MUTATIONS.CLOSE_MESSAGE_MODAL](state) {
      state.messageModal = null;
    },
    [BASE_MUTATIONS.CONFIRM_MODAL](state, confirmModal) {
      state.confirmModal = confirmModal;
    },
    [BASE_MUTATIONS.CLOSE_CONFIRM_MODAL](state) {
      state.confirmModal = null;
    },
    [BASE_MUTATIONS.TRANSACTION_TOAST](state, { hash, status }) {
      state.transactionToast[hash] = status;
    },
    [BASE_MUTATIONS.REMOVE_TRANSACTION_TOAST](state, hash) {
      delete state.transactionToast[hash];
    },
    [BASE_MUTATIONS.RESET_TRANSACTION_TOAST](state) {
      state.transactionToast = {};
    },
    [BASE_MUTATIONS.SIGN_TRANSACTION_TOAST](state, value = true) {
      state.signTransactionsToast = value;
    },
    [BASE_MUTATIONS.TOGGLE_THEME](state) {
      if (GENERAL.THEMES.DARK === state.theme) {
        state.theme = GENERAL.THEMES.LIGHT;
      } else {
        state.theme = GENERAL.THEMES.DARK;
      }

      localStorage.setItem(GENERAL.STORAGE.THEME, state.theme);
    },
    [BASE_MUTATIONS.REWARDS_BALANCE](state, rewardsBalance) {
      state.rewardsBalance = rewardsBalance;
    },
  },
  actions: {
    async [BASE_ACTIONS.GET_REWARDS_BALANCE]({ commit }) {
      const egldValue = await elrondApiHelper.getEgldTokenPrice();
      const rewardsBalance = await elrondApiHelper.getAccount(BLOCKCHAIN.REWARDS_ADDRESS);

      const usd = new BigNumber(rewardsBalance.balance.toString()).multipliedBy(egldValue).decimalPlaces(0);

      commit(BASE_MUTATIONS.REWARDS_BALANCE, {
        egld: rewardsBalance.balance,
        usd,
      });
    },
  },
  modules: {
    blackjack: blackjackStore,
    hideAndSeek: hideAndSeekStore,
    nftStaking: nftStakingStore,
    oneVsOne: oneVsOneStore,
    poolsStaking: poolsStakingStore,
    referral: referralStore,
    rps: rpsStore,
    staking: stakingStore,
    tokenFlip: tokenFlipStore,
    user: userStore,
    nutsRoulette: nutsRouletteStore,
  }
});

export default store;