import {
  AbiRegistry,
  Address,
  Interaction,
  ResultsParser,
  SmartContract,
  Struct,
  Transaction,
  VariadicValue,
} from "@multiversx/sdk-core";
import axios from "axios";
import elrondHelper from "@/helpers/elrond";

const BASE_FUNCTIONS = {
  CLAIM_REFERAL_REWARDS: "claim_referer_rewards",
};

abstract class BaseContract {
  public contract: SmartContract;
  public abiRegistry: AbiRegistry;
  public resultParser: ResultsParser;

  abiJson: string;
  abiInterface: string;

  constructor(address: string, abiJson: string, abiInterface: string) {
    this.contract = new SmartContract({
      address: new Address(address),
    });
    this.resultParser = new ResultsParser();

    this.abiJson = abiJson;
    this.abiInterface = abiInterface;
  }

  public async getContractAbi(): Promise<AbiRegistry> {
    if (!this.abiRegistry) {
      const response = await axios.get(`/abi/${this.abiJson}.abi.json`);

      this.abiRegistry = AbiRegistry.create(response.data);

      this.contract = new SmartContract({
        address: this.contract.getAddress(),
        abi: this.abiRegistry,
      });
    }

    return this.abiRegistry;
  }

  public async getContractAbiRegistry(): Promise<AbiRegistry> {
    const response = await axios.get(`/abi/${this.abiJson}.abi.json`);

    return AbiRegistry.create(response.data);
  }

  async getRefererRewards(address: string): Promise<{ [key: string]: number } | null> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.referer_rewards([address]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    if (!response.returnData.length) {
      return null;
    }

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return (firstValue as VariadicValue).getItems().reduce((acc: any, value: Struct) => {
      acc[value.getFieldValue("field0")] = value.getFieldValue("field1").toNumber();

      return acc;
    }, {});
  }

  async claimRefererRewards(): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[BASE_FUNCTIONS.CLAIM_REFERAL_REWARDS]()
      .withGasLimit(7000000) // 5,000,000 from Mandos + 2,000,000 just in case
      .withChainID(elrondHelper.networkConfig.ChainID);

    return interaction.buildTransaction();
  }
}

export default BaseContract;
