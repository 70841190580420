import storageHelper from "@/helpers/storage";
import gamesProxyContract from "@/contracts/gamesProxy";
import { Account, Transaction } from "@multiversx/sdk-core";
import GENERAL from "@/constants/general";
import hideAndSeekContract from "@/contracts/hideAndSeek";
import tokenFlipContract from "@/contracts/tokenFlip";
import oneVsOneContract from "@/contracts/oneVsOne";
import BaseContract from "@/contracts/baseContract";
import elrondHelper from "@/helpers/elrond";
import nutsRoulette from "@/contracts/nutsRoulette";
import nutsRouletteContract from "@/contracts/nutsRoulette";

export const REFERRAL_GETTERS = {
  REFERER: "referralReferer",
  NUMBER_OF_REFERALS: 'referralNumberOfReferals',
  REFERAL_REWARDS: 'referralReferalRewards',
};

export const REFERRAL_MUTATIONS = {
  REFERER: 'referralReferer',
  NUMBER_OF_REFERALS: 'referralNumberOfReferals',
  REFERAL_REWARDS: 'referralReferalRewards',
  RESET: 'referralReset',
}

export const REFERRAL_ACTIONS = {
  GET_REFERER: 'referralGetReferer',
  HANDLE_REFERER: 'referralHandleReferer',
  GET_NUMBER_OF_REFERALS: 'referralGetNumberOfReferals',
  GET_REFERAL_REWARDS: 'referralGetReferalRewards',
  CLAIM_REFERAL_REWARDS: 'referralClaimReferalRewards',
}

interface IReferralStore {
  state: () => {
    referer: string | null;
    numberOfReferals: number | null;
    referalRewards: {
      [key: string]: {
        [key: string]: number,
      },
    } | null
  };

  [index: string]: any;
}

export const referralStore: IReferralStore = {
  state: () => ({
    referer: null,
    numberOfReferals: null,
    referalRewards: null,
  }),
  getters: {
    [REFERRAL_GETTERS.REFERER](state) {
      return state.referer;
    },
    [REFERRAL_GETTERS.NUMBER_OF_REFERALS](state) {
      return state.numberOfReferals;
    },
    [REFERRAL_GETTERS.REFERAL_REWARDS](state) {
      return state.referalRewards;
    },
  },
  mutations: {
    [REFERRAL_MUTATIONS.REFERER](state, referer) {
      state.referer = referer;
    },
    [REFERRAL_MUTATIONS.NUMBER_OF_REFERALS](state, numberOfReferals) {
      state.numberOfReferals = numberOfReferals;
    },
    [REFERRAL_MUTATIONS.REFERAL_REWARDS](state, referalRewards) {
      state.referalRewards = referalRewards;
    },
    [REFERRAL_MUTATIONS.RESET](state) {
      state.referer = null;
      state.numberOfReferals = null;
      state.referalRewards = null;
    }
  },
  actions: {
    async [REFERRAL_ACTIONS.GET_REFERER]({ commit }, account: Account) {
      const address = account.address.bech32();

      let referer = storageHelper.getReferer(address);

      if (referer && referer !== address) {
        const newReferer = storageHelper.getReferer('');

        if (!newReferer) {
          commit(REFERRAL_MUTATIONS.REFERER, referer);

          return referer;
        }
      }

      referer = await gamesProxyContract.getReferer(account);

      if (!referer) {
        referer = storageHelper.getReferer('');

        if (referer === address) {
          storageHelper.setReferer('', null);
          referer = null;
        }
      }

      commit(REFERRAL_MUTATIONS.REFERER, referer);
      storageHelper.setReferer(address, referer);

      return referer;
    },
    async [REFERRAL_ACTIONS.HANDLE_REFERER]() {
      const query = new URLSearchParams(window.location.search);

      if (!query.has(GENERAL.REFERER_PARAM)) {
        return;
      }

      const referer = query.get(GENERAL.REFERER_PARAM);

      storageHelper.setReferer('', referer);

      query.delete(GENERAL.REFERER_PARAM);

      window.location.search = query.toString();
    },
    async [REFERRAL_ACTIONS.GET_NUMBER_OF_REFERALS]({ commit, getters }, address: string) {
      if (null !== getters[REFERRAL_GETTERS.NUMBER_OF_REFERALS]) {
        return getters[REFERRAL_GETTERS.NUMBER_OF_REFERALS];
      }

      const numberOfReferals = await gamesProxyContract.getNumberOfReferals(address);

      commit(REFERRAL_MUTATIONS.NUMBER_OF_REFERALS, numberOfReferals);

      return numberOfReferals;
    },
    async [REFERRAL_ACTIONS.GET_REFERAL_REWARDS]({ commit, getters }, address: string) {
      if (null !== getters[REFERRAL_GETTERS.REFERAL_REWARDS]) {
        return getters[REFERRAL_GETTERS.REFERAL_REWARDS];
      }

      const hideAndSeek = await hideAndSeekContract.getRefererRewards(address);
      const tokenFlip = await tokenFlipContract.getRefererRewards(address);
      const oneVsOne = await oneVsOneContract.getRefererRewards(address);
      const nutsRoulette = await nutsRouletteContract.getRefererRewards(address);

      commit(REFERRAL_MUTATIONS.REFERAL_REWARDS, { hideAndSeek, tokenFlip, oneVsOne, nutsRoulette });
    },
    async [REFERRAL_ACTIONS.CLAIM_REFERAL_REWARDS]({ getters }, account: Account) {
      const referalRewards = getters[REFERRAL_GETTERS.REFERAL_REWARDS];

      if (null === referalRewards) {
        return;
      }

      const transactions: Transaction[] = [];

      for (const contract in referalRewards) {
        const total = Object.values(referalRewards[contract]).reduce((total: number, value: number) => {
          total += value;

          return total;
        }, 0);

        if (!total) {
          continue;
        }

        let contractClass: BaseContract = null;

        switch (contract) {
          case 'hideAndSeek':
            contractClass = hideAndSeekContract;
            break;
          case 'tokenFlip':
            contractClass = tokenFlipContract;
            break;
          case 'oneVsOne':
            contractClass = oneVsOneContract;
            break;
          case 'nutsRoulette':
            contractClass = nutsRouletteContract;
            break;
        }

        // Will not happen
        if (!contractClass) {
          continue;
        }

        transactions.push(await contractClass.claimRefererRewards());
      }

      return await elrondHelper.sendTransactions(transactions, account);
    },
  },
};
