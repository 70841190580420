import BLOCKCHAIN from "@/constants/blockchain";
import BaseContract from "@/contracts/baseContract";

export const HIDE_AND_SEEK_FUNCTIONS = {
  PLAY: "play_times",
  PLAY_RPS: "play_rps",
};

class HideAndSeekContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.CONTRACTS.HIDE_AND_SEEK, 'hide-and-seek', 'HideAndSeek');
  }
}

const hideAndSeekContract = new HideAndSeekContract();

export default hideAndSeekContract;
