import BLOCKCHAIN from "@/constants/blockchain";
import BaseContract from "@/contracts/baseContract";
import { BigNumber } from "bignumber.js";
import elrondHelper from "@/helpers/elrond";
import {
  BigUIntValue,
  ContractFunction,
  Struct, TokenPayment, TokenTransfer,
  Tuple,
  U64Value,
  U8Value,
  VariadicValue,
} from "@multiversx/sdk-core/out";

export const NUTS_ROULETTE_FUNCTIONS = {
  PLAY: "play",
};

export const NUTS_ROULETTE_CONSTANTS = {
  NUTS_PER_BULLET: 25_000_000_000, // 25k NUTS
  WEGLD_PER_BULLET: 20_000_000_000_000_000n, // 0.02 WEGLD

  GO_NUTS_WEGLD_WITH_DISCOUNT: 0.9 // 90% discount
};

class NutsRouletteContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.CONTRACTS.NUTS_ROULETTE, "nuts-roulette", "NutsRoulette");
  }

  async getActualNutsJackpot(): Promise<[BigNumber, BigNumber, BigNumber]> {
    await this.getContractAbi();

    const query = this.contract.createQuery({
      func: new ContractFunction("actual_nuts_jackpot"),
      args: [],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("actual_nuts_jackpot");
    const { firstValue, secondValue, thirdValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return [
      (firstValue as BigUIntValue).valueOf(),
      (secondValue as BigUIntValue).valueOf(),
      (thirdValue as BigUIntValue).valueOf(),
    ];
  }

  async getSpecialChance(): Promise<number> {
    await this.getContractAbi();

    const query = this.contract.createQuery({
      func: new ContractFunction("special_chance"),
      args: [],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("special_chance");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as U64Value).valueOf().toNumber();
  }

  async getWegldJackpot(): Promise<BigNumber> {
    await this.getContractAbi();

    const query = this.contract.createQuery({
      func: new ContractFunction("wegld_jackpot"),
      args: [],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("wegld_jackpot");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as BigUIntValue).valueOf();
  }

  async getNutsNfts(): Promise<number[]> {
    await this.getContractAbi();

    const query = this.contract.createQuery({
      func: new ContractFunction("nuts_nfts"),
      args: [],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("nuts_nfts");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue).valueOf().map((v: BigNumber) => v.toNumber());
  }

  async getNextGenNfts(): Promise<number[]> {
    await this.getContractAbi();

    const query = this.contract.createQuery({
      func: new ContractFunction("next_gen_nfts"),
      args: [],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("next_gen_nfts");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue).valueOf().map((v: BigNumber) => v.toNumber());
  }

  async getExtraSpecialJackpots(jackpotType: number): Promise<TokenTransfer[]> {
    await this.getContractAbi();

    const query = this.contract.createQuery({
      func: new ContractFunction("extra_special_jackpots"),
      args: [new U8Value(jackpotType)],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = this.contract.getEndpoint("extra_special_jackpots");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue).getItems().map((v: Struct) => (new TokenTransfer({
      tokenIdentifier: v.getFieldValue('token_identifier'),
      nonce: v.getFieldValue('token_nonce').toNumber(),
      amountAsBigInteger: v.getFieldValue('amount'),
    })));
  }
}

const nutsRouletteContract = new NutsRouletteContract();

export default nutsRouletteContract;
