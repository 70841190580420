
import { USER_ACTIONS, USER_GETTERS } from "@/store/user";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import MessageModal from "@/components/info/MessageModal.vue";
import ConfirmModal from "@/components/info/ConfirmModal.vue";
import { BASE_GETTERS, BASE_MUTATIONS } from "@/store";
import PageLoader from "@/components/helpers/PageLoader.vue";
import TransactionsToast from "@/components/info/TransactionsToast.vue";
import { ROUTES } from "@/router/constants";
import SidebarMobile from "@/components/base/SidebarMobile.vue";
import SidebarCommon from "@/components/base/SidebarCommon.vue";
import { REFERRAL_ACTIONS } from "@/store/referral";
import { useRoute } from "vue-router";

export default {
  name: 'App',
  components: { SidebarCommon, SidebarMobile, TransactionsToast, PageLoader, ConfirmModal, MessageModal},
  created() {
    this.ROUTES = ROUTES;
  },
  setup() {
    const store = useStore();
    const account = computed(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]);
    const theme = computed(() => store.getters[BASE_GETTERS.THEME]);

    const loading = ref(true);

    onMounted(async () => {
      loading.value = true;

      try {
        await store.dispatch(REFERRAL_ACTIONS.HANDLE_REFERER);

        await store.dispatch(USER_ACTIONS.LOGIN_RECHECK);
      } catch (e) {
        console.error(e);

        store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
          variant: 'danger',
          message: 'messages.error.general',
        });
      }

      loading.value = false;
    });

    // Get the account referer
    watch(account, (value, oldValue) => {
      if (value && value !== oldValue) {
        store.dispatch(REFERRAL_ACTIONS.GET_REFERER, value);
      }
    });

    const route = useRoute();

    return {
      theme,
      loading,
      route,
    };
  }
}
