import api, { API_PATHS } from "@/api/index";
import { PoolStakingModel } from "@/api/models/poolsStaking.model";
import { BigNumber } from "bignumber.js";
import { TokenTransfer } from "@multiversx/sdk-core/out";

class StakingClient {
  async allPools(address?: string): Promise<PoolStakingModel[] | null> {
    try {
      const response = await api.get(API_PATHS.STAKING_POOLS, {}, { address });

      return response.data.map((data) => ({
        ...data,
        reward: {
          ...data.reward,
          rewardPerDay: TokenTransfer.fungibleFromBigInteger(
            data.reward.rewardToken,
            new BigNumber(data.reward.rewardPerDay),
            data.reward.rewardTokenDecimals
          ),
          rewardCapacity: new BigNumber(data.reward.rewardCapacity),
          farmTokenSupply: TokenTransfer.fungibleFromBigInteger(
            data.reward.token,
            new BigNumber(data.reward.farmTokenSupply),
            data.reward.tokenDecimals
          ),
        },
        positions: (data?.positions || []).map((position) => ({
          ...position,
          amount: TokenTransfer.fungibleFromBigInteger(data.reward.token, position.amount, data.reward.tokenDecimals),
          originalAmount: !position.boosted
            ? TokenTransfer.fungibleFromBigInteger(data.reward.token, position.amount, data.reward.tokenDecimals)
            : TokenTransfer.fungibleFromBigInteger(
                data.reward.token,
                new BigNumber(position.amount).multipliedBy(100, 10).dividedToIntegerBy(105, 10),
                data.reward.tokenDecimals
              ),
          rewardPerShare: new BigNumber(position.rewardPerShare),
          rewardPerDay: TokenTransfer.fungibleFromBigInteger(
            data.reward.rewardToken,
            position.rewardPerDay,
            data.reward.rewardTokenDecimals
          ),
          calculatedReward: TokenTransfer.fungibleFromBigInteger(
            data.reward.rewardToken,
            position.calculatedReward || "0",
            data.reward.rewardTokenDecimals
          ),
        })),
      }));
    } catch (e) {
      console.error(e);

      return null;
    }
  }
}

const stakingClient = new StakingClient();

export default stakingClient;
