import storeHelper, { LIST } from "@/helpers/store";
import oneVsOneContract, { GAME, ONE_VS_ONE_FUNCTIONS, ONE_VS_ONE_MESSAGES } from "@/contracts/oneVsOne";
import { BytesValue, TokenTransfer } from "@multiversx/sdk-core";
import elrondApiHelper from "@/helpers/elrondApi";
import BLOCKCHAIN from "@/constants/blockchain";
import gamesProxyContract, { GAMES_PROXY_FUNCTIONS } from "@/contracts/gamesProxy";
import elrondHelper from "@/helpers/elrond";
import { REFERRAL_GETTERS } from "@/store/referral";
import { TransactionOnNetwork } from "@multiversx/sdk-network-providers/out";
import { TransactionDecoder } from "@multiversx/sdk-transaction-decoder/lib/src/transaction.decoder";
import storageHelper from "@/helpers/storage";
import TRANSACTION_TYPES from "@/constants/transactionTypes";

export const ONE_VS_ONE_GETTERS = {
  GAMES: "oneVsOneGames",
  RECENT_GAMES: "oneVsOneRecentGames",
};

export const ONE_VS_ONE_MUTATIONS = {
  GAMES: "oneVsOneGames",
  RECENT_GAMES: "oneVsOneRecentGames",
};

export const ONE_VS_ONE_ACTIONS = {
  CREATE: "oneVsOneCreate",
  CANCEL: "oneVsOneCancel",
  PLAY: "oneVsOnePlay",
  GET_GAMES: "oneVsOneGetGames",
  GET_RECENT_GAMES: "oneVsOneGetRecentGames",
};

export const ONE_VS_ONE_ERRORS = {
  NOT_EXIST: "Game does not exist",
};

interface RECENT_GAME {
  hash: string;
  index: number;
  address: string;
  gameAddress: string;
  tokenTransfer: TokenTransfer;
  status: boolean;
  timestamp: number;
}

interface IOneVsOneStore {
  state: () => {
    games?: LIST<GAME>;
    recentGames: {
      address: string;
      list: RECENT_GAME[];
    };
  };

  [index: string]: any;
}

export const oneVsOneStore: IOneVsOneStore = {
  state: () => ({
    games: null,
    recentGames: {
      address: null,
      list: [],
    },
  }),
  getters: {
    [ONE_VS_ONE_GETTERS.GAMES](state) {
      return state.games;
    },
    [ONE_VS_ONE_GETTERS.RECENT_GAMES](state) {
      return state.recentGames;
    },
  },
  mutations: {
    [ONE_VS_ONE_MUTATIONS.GAMES](state, games) {
      state.games = games;
    },
    [ONE_VS_ONE_MUTATIONS.RECENT_GAMES](state, recentGames) {
      state.recentGames = recentGames;
    },
  },
  actions: {
    async [ONE_VS_ONE_ACTIONS.CREATE](_, { accountElrond, amount }) {
      return await oneVsOneContract.createGame(accountElrond, amount);
    },
    async [ONE_VS_ONE_ACTIONS.CANCEL](_, { accountElrond, id }) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.ONE_VS_ONE.CANCEL, accountElrond.nonce);

      return await oneVsOneContract.cancelGame(accountElrond, id);
    },
    async [ONE_VS_ONE_ACTIONS.PLAY]({ getters }, { accountElrond, id, collection, token, amount }) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.ONE_VS_ONE.PLAY, accountElrond.nonce, { gameId: id });

      return await gamesProxyContract.oneVsOne(
        accountElrond,
        id,
        collection,
        token,
        amount,
        getters[REFERRAL_GETTERS.REFERER]
      );
    },
    async [ONE_VS_ONE_ACTIONS.GET_GAMES]({ commit, getters }, { page }) {
      const total = await oneVsOneContract.gamesLen();

      let games = [];
      if (total) {
        games = await oneVsOneContract.getGames(page);
      }

      if (0 === page) {
        storeHelper.commitList(commit, ONE_VS_ONE_MUTATIONS.GAMES, games, page, total);

        return;
      }

      const oldGames = getters[ONE_VS_ONE_MUTATIONS.GAMES]?.items;
      const newGames = [...oldGames, ...games];

      storeHelper.commitList(commit, ONE_VS_ONE_MUTATIONS.GAMES, newGames, page, total);
    },
    async [ONE_VS_ONE_ACTIONS.GET_RECENT_GAMES]({ commit, getters }, address = null) {
      const previousGames = getters[ONE_VS_ONE_GETTERS.RECENT_GAMES];
      if (previousGames.address === address && previousGames.list.length) {
        return;
      }

      commit(ONE_VS_ONE_MUTATIONS.RECENT_GAMES, {
        address,
        list: [],
      });

      const response = await elrondApiHelper.getTransactionsToProxy(GAMES_PROXY_FUNCTIONS.ONE_VS_ONE, address);

      await oneVsOneContract.getContractAbi();

      const decoder = new TransactionDecoder();

      let index = 0;

      const recentGames: RECENT_GAME[] = response
        .map((transaction: TransactionOnNetwork) => {
          const results = elrondHelper.parseCustomResults(
            transaction,
            oneVsOneContract.contract.getEndpoint(ONE_VS_ONE_FUNCTIONS.PLAY)
          );

          if (!results) {
            return null;
          }

          const { firstValue } = results;

          const metadata = decoder.getTransactionMetadata({
            sender: transaction.sender.bech32(),
            receiver: transaction.receiver.bech32(),
            data: transaction.data.toString("base64"),
            value: transaction.value.toString(),
          });

          const tokenTransfer = !metadata.transfers
            ? TokenTransfer.egldFromBigInteger(transaction.value)
            : TokenTransfer.fungibleFromBigInteger(
                metadata.transfers[0].properties.identifier,
                metadata.transfers[0].value.toString(),
                BLOCKCHAIN.TOKENS[metadata.transfers[0].properties.identifier]?.decimals || 18
              );

          index++;

          const status = "lucky" === (firstValue as BytesValue).toString();

          let gameAddress: string;
          if (status) {
            // Search for Lost game on ElrondBet text in contracts
            const result = transaction.contractResults.items.filter(
              (sc) => ONE_VS_ONE_MESSAGES.GAME_OWNER_LOST === sc.data
            )[0];

            gameAddress = result ? result.receiver.bech32() : "";
          } else {
            let result;
            if (tokenTransfer.isEgld()) {
              result = transaction.contractResults.items.filter(
                (sc) => ONE_VS_ONE_MESSAGES.GAME_OWNER_WON === sc.data
              )[0];
            } else {
              result = transaction.contractResults.items.filter((sc) =>
                sc.data.endsWith(Buffer.from(ONE_VS_ONE_MESSAGES.GAME_OWNER_WON).toString("hex"))
              )[0];
            }

            gameAddress = result ? result.receiver.bech32() : "";
          }

          return {
            index,
            hash: transaction.hash,
            address: transaction.sender.bech32(),
            gameAddress,
            tokenTransfer,
            status,
            timestamp: transaction.timestamp,
          };
        })
        .filter((value) => null !== value);

      commit(ONE_VS_ONE_MUTATIONS.RECENT_GAMES, {
        address,
        list: recentGames,
      });
    },
  },
};
