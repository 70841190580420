import { INonce } from "@multiversx/sdk-core/out";
import elrondHelper, { WALLET_LOGIN_TYPE } from "@/helpers/elrond";
import { WalletProvider } from "@multiversx/sdk-web-wallet-provider/out";

const STORAGE_KEYS = {
  WALLET_LOGIN: "wallet_login",
  WALLET_LOGIN_TYPE: "wallet_login_type",
  EXTENSION_LOGIN: "extension_login",
  MAIAR_LOGIN: "maiar_login",
  LEDGER_LOGIN: "ledger_login",
  WEBVIEW_LOGIN: "webview_login",

  BACKEND_LOGIN_TOKEN: "backend_login_token",
  BACKEND_ACCESS_TOKEN: "backend_access_token",

  REFERER: "referer_",

  TRANSACTION_TO_WATCH: "transaction_to_watch_",
};

const storageHelper = {
  // Default ttl for an item in storage is 7 days
  setItem: (key, item, ttl = 604800 * 1000) => {
    const expires = Date.now() + ttl;

    sessionStorage.setItem(
      key,
      JSON.stringify({
        expires,
        data: item,
      })
    );
  },
  setLocalItem: (key, item, ttl = null) => {
    if (!ttl) {
      localStorage.setItem(key, JSON.stringify(item));

      return;
    }

    const expires = Date.now() + ttl;

    localStorage.setItem(
      key,
      JSON.stringify({
        expires,
        data: item,
      })
    );
  },
  getItem: (key) => {
    const item = sessionStorage.getItem(key);
    if (null === item) {
      return null;
    }

    const deserializedItem = JSON.parse(item);
    if (!deserializedItem) {
      return null;
    }

    if (!deserializedItem?.expires || null === deserializedItem?.data) {
      return null;
    }

    const expired = Date.now() >= deserializedItem.expires;
    if (expired) {
      sessionStorage.removeItem(key);

      return null;
    }

    return deserializedItem.data;
  },
  getLocalItem: (key, expires = false) => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    const deserializedItem = JSON.parse(item);
    if (!deserializedItem) {
      return null;
    }

    if (!expires) {
      return deserializedItem;
    }

    if (!deserializedItem?.expires || !deserializedItem?.data) {
      return null;
    }

    const expired = Date.now() >= deserializedItem.expires;
    if (expired) {
      localStorage.removeItem(key);

      return null;
    }

    return deserializedItem.data;
  },
  deleteItem: (key) => {
    sessionStorage.removeItem(key);
  },
  deleteLocalItem: (key) => {
    localStorage.removeItem(key);
  },

  setWalletLogin(address = null) {
    if (address) {
      storageHelper.clearLogins();

      storageHelper.setItem(STORAGE_KEYS.WALLET_LOGIN, address);
    } else {
      storageHelper.setItem(STORAGE_KEYS.WALLET_LOGIN, false, 120 * 1000); // 2 minutes
    }
  },
  getWalletLogin() {
    return storageHelper.getItem(STORAGE_KEYS.WALLET_LOGIN);
  },

  setWalletLoginType(type: WALLET_LOGIN_TYPE) {
    storageHelper.setItem(STORAGE_KEYS.WALLET_LOGIN_TYPE, type);
  },
  getWalletLoginType() {
    return storageHelper.getItem(STORAGE_KEYS.WALLET_LOGIN_TYPE);
  },

  setExtensionLogin(address) {
    storageHelper.clearLogins();

    storageHelper.setLocalItem(STORAGE_KEYS.EXTENSION_LOGIN, address, 604800 * 1000); // 7 days
  },
  getExtensionLogin() {
    return storageHelper.getLocalItem(STORAGE_KEYS.EXTENSION_LOGIN, true);
  },

  setMaiarLogin(address) {
    storageHelper.clearLogins();

    storageHelper.setLocalItem(STORAGE_KEYS.MAIAR_LOGIN, address, 604800 * 1000); // 7 days
  },
  getMaiarLogin() {
    return storageHelper.getLocalItem(STORAGE_KEYS.MAIAR_LOGIN, true);
  },

  setLedgerLogin(addressIndex, address) {
    storageHelper.clearLogins();

    storageHelper.setItem(STORAGE_KEYS.LEDGER_LOGIN, { addressIndex, address });
  },
  getLedgerLogin() {
    return storageHelper.getItem(STORAGE_KEYS.LEDGER_LOGIN);
  },

  setWebViewLogin(address) {
    storageHelper.clearLogins();

    storageHelper.setLocalItem(STORAGE_KEYS.WEBVIEW_LOGIN, address, 604800 * 1000); // 7 days
  },
  getWebViewLogin() {
    return storageHelper.getLocalItem(STORAGE_KEYS.WEBVIEW_LOGIN, true);
  },

  clearLogins(includeBackend = false, address = null) {
    storageHelper.deleteLocalItem(STORAGE_KEYS.EXTENSION_LOGIN);
    storageHelper.deleteLocalItem(STORAGE_KEYS.MAIAR_LOGIN);
    storageHelper.deleteLocalItem(STORAGE_KEYS.WEBVIEW_LOGIN);
    storageHelper.deleteItem(STORAGE_KEYS.WALLET_LOGIN);
    storageHelper.deleteItem(STORAGE_KEYS.LEDGER_LOGIN);

    if (includeBackend) {
      storageHelper.deleteItem(STORAGE_KEYS.WALLET_LOGIN_TYPE);
      storageHelper.deleteItem(STORAGE_KEYS.BACKEND_ACCESS_TOKEN);

      if (address) {
        storageHelper.deleteLocalItem(STORAGE_KEYS.REFERER + address);
      }
    }
  },

  setBackendLoginToken(token) {
    storageHelper.setItem(STORAGE_KEYS.BACKEND_LOGIN_TOKEN, token, 600 * 1000); // 10 minutes
  },
  getBackendLoginToken() {
    return storageHelper.getItem(STORAGE_KEYS.BACKEND_LOGIN_TOKEN);
  },

  setBackendAccessToken(token) {
    storageHelper.deleteItem(STORAGE_KEYS.BACKEND_LOGIN_TOKEN);

    storageHelper.setItem(STORAGE_KEYS.BACKEND_ACCESS_TOKEN, token);
  },
  getBackendAccessToken() {
    return storageHelper.getItem(STORAGE_KEYS.BACKEND_ACCESS_TOKEN);
  },

  setReferer(address: string, referer) {
    if (address) {
      storageHelper.deleteLocalItem(STORAGE_KEYS.REFERER);
    }

    storageHelper.setLocalItem(STORAGE_KEYS.REFERER + address, referer);
  },
  getReferer(address: string) {
    return storageHelper.getLocalItem(STORAGE_KEYS.REFERER + address);
  },

  setTransactionToWatch(type: string, nonce: INonce = null, extraData = {}) {
    if (!nonce) {
      storageHelper.deleteItem(STORAGE_KEYS.TRANSACTION_TO_WATCH + type);
    } else if (elrondHelper.provider instanceof WalletProvider || Object.keys(extraData).length > 0) {
      storageHelper.setItem(
        STORAGE_KEYS.TRANSACTION_TO_WATCH + type,
        { nonce: nonce.valueOf(), ...extraData },
        300 * 1000
      ); // 5 minutes
    }
  },
  getTransactionToWatch(type: string) {
    return storageHelper.getItem(STORAGE_KEYS.TRANSACTION_TO_WATCH + type);
  },
};

export default storageHelper;
