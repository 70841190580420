import ELROND from "@/constants/elrond";
import axios, { AxiosInstance } from "axios";

export interface NftPrice {
  collection: string;
  price: number;
  usdPrice: number;
}

const PATHS = {
  FLOOR_PRICE: (collectionId: string) => `/collection/${collectionId}/floor-price`,
};

class XoxnoApi {
  client: AxiosInstance;

  cache: { [token: string]: NftPrice } = {};

  constructor() {
    this.client = axios.create({
      baseURL: ELROND.XOXNO_API,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 30000,
    });
  }

  async getNftFloorPrice(collectionId: string): Promise<NftPrice | null> {
    if (this.cache[collectionId]) {
      return this.cache[collectionId];
    }

    try {
      const response = await this.client.get(PATHS.FLOOR_PRICE(collectionId));

      this.cache[collectionId] = response.data;

      return this.cache[collectionId];
    } catch(e) {
      console.error(e);

      return null;
    }
  }
}

const xoxnoApi = new XoxnoApi();

export default xoxnoApi;
