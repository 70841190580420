import { STAKED_POSITION_FULL } from "@/store/staking";

const TRANSACTION_TYPES = {
  BLACKJACK: {
    INIT: 'blackjack_init',
    HIT: 'blackjack_hit',
    STAND: 'blackjack_stand',
  },

  ONE_VS_ONE: {
    PLAY: 'one_vs_one_play',
    CANCEL: 'one_vs_one_cancel',
  },

  NFT_STAKING: {
    STAKE: 'nft_staking_stake',
    UNSTAKE: 'nft_staking_unstake',
    CLAIM: 'nft_staking_claim',
    UPGRADE: 'nft_staking_upgrade',
  },

  NUTS_STAKING: {
    STAKE: (position: STAKED_POSITION_FULL) => `nuts_staking_stake_${position.lockingPeriod}_${position.nonce}`,
    UNSTAKE: (nonce: number) => `nuts_staking_unstake_${nonce}`,
    CLAIM: (nonce: number) => `nuts_staking_claim_${nonce}`,
    COMPOUND: (nonce: number) => `nuts_staking_compound_${nonce}`,
  },

  POOL_STAKING: {
    STAKE: (type: string, nonce: number) => `pool_staking_stake_${type}_${nonce}`,
    UNSTAKE: (type: string, nonce: number) => `pool_staking_unstake_${type}_${nonce}`,
    CLAIM: (type: string, nonce: number) => `pool_staking_claim_${type}_${nonce}`,
  },
};

export default TRANSACTION_TYPES;
