import {
  Account,
  ContractFunction,
  Struct,
  TokenTransfer,
  Transaction,
  U32Value,
  VariadicValue,
} from "@multiversx/sdk-core";
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import BaseContract from "@/contracts/baseContract";

export const ONE_VS_ONE_FUNCTIONS = {
  CREATE_GAME: "create_game",
  CANCEL_GAME: "cancel_game",
  PLAY: "play",
};

export const ONE_VS_ONE_MESSAGES = {
  GAME_OWNER_LOST: "Lost game on ElrondBet",
  GAME_OWNER_WON: "ElrondBet winnings after fees",
};

export interface GAME {
  creator: string;
  tokenTransfer: TokenTransfer;
  id: number;
}

class OneVsOneContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.CONTRACTS.ONE_VS_ONE, "one-vs-one", "OneVsOne");
  }

  // Endpoints
  async createGame(account: Account, tokenTransfer: TokenTransfer): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[ONE_VS_ONE_FUNCTIONS.CREATE_GAME]([]).withGasLimit(7000000); // 5,000,000 from Mandos + 2,000,000 just in case

    if (tokenTransfer.isEgld()) {
      interaction.withValue(tokenTransfer);
    } else {
      interaction.withSingleESDTTransfer(tokenTransfer);
    }

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async cancelGame(account: Account, id: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[ONE_VS_ONE_FUNCTIONS.CANCEL_GAME]([id]).withGasLimit(7000000); // 5,000,000 from Mandos + 2,000,000 just in case

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  // Views
  async gamesLen(): Promise<number> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("games_len"),
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("games_len");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return firstValue.valueOf().toNumber();
  }

  async getGames(page: number): Promise<GAME[]> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("get_games"),
      args: [new U32Value(page)],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("get_games");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue).getItems().map((result: Struct) => {
      const game = result.getFieldValue("field0");

      return {
        creator: game.creator.bech32(),
        tokenTransfer: TokenTransfer.fungibleFromBigInteger(
          game.token,
          game.amount,
          BLOCKCHAIN.TOKENS[game.token]?.decimals || 18
        ),
        id: result.getFieldValue("field1").toNumber(),
      };
    });
  }
}

const oneVsOneContract = new OneVsOneContract();

export default oneVsOneContract;
