import { BigNumber } from "bignumber.js";
import nutsRouletteContract, { NUTS_ROULETTE_CONSTANTS } from "@/contracts/nutsRoulette";
import elrondHelper from "@/helpers/elrond";
import { TokenTransfer, Transaction } from "@multiversx/sdk-core/out";
import wegldSwapContract from "@/contracts/wegldSwap";
import gamesProxyContract from "@/contracts/gamesProxy";
import { REFERRAL_GETTERS } from "@/store/referral";
import BLOCKCHAIN from "@/constants/blockchain";
import elrondApiHelper, { NFT_POST } from "@/helpers/elrondApi";
import ELROND from "@/constants/elrond";

export const NUTS_ROULETTE_GETTERS = {
  REWARDS: "nutsRouletteRewards",
};

export const NUTS_ROULETTE_MUTATIONS = {
  REWARDS: "nutsRouletteRewards",
};

export const NUTS_ROULETTE_ACTIONS = {
  PLAY: "nutsRoulettePlay",
  GET_REWARDS: "nutsRouletteGetRewards",
};

export interface REWARD {
  jackpot: BigNumber;
  nextGenJackpot: BigNumber;
  superJackpot: BigNumber;
  superJackpotWegld: BigNumber;
  specialChance: number;
  nutsNft?: NFT_POST;
  nextGenNft?: NFT_POST;
  wegldPerBullet: bigint;
  extraSpecialNormal?: TokenTransfer | NFT_POST;
  extraSpecialNextGen?: TokenTransfer | NFT_POST;
  extraSpecialSuper?: TokenTransfer | NFT_POST;
}

interface INutsRouletteStore {
  state: () => {
    rewards: REWARD;
  };

  [index: string]: any;
}

export const nutsRouletteStore: INutsRouletteStore = {
  state: () => ({
    rewards: null,
  }),
  getters: {
    [NUTS_ROULETTE_GETTERS.REWARDS](state) {
      return state.rewards;
    },
  },
  mutations: {
    [NUTS_ROULETTE_MUTATIONS.REWARDS](state, rewards) {
      state.rewards = rewards;
    },
  },
  actions: {
    async [NUTS_ROULETTE_ACTIONS.PLAY](
      { getters },
      { accountElrond, nbOfBullets, wegldBalance, jackpotType, selectedNftNonce, goNutsEgldPerBullet }
    ) {
      const transactions: Transaction[] = [];

      const rewards: REWARD = getters[NUTS_ROULETTE_GETTERS.REWARDS];

      const requiredNuts = new BigNumber(nbOfBullets * NUTS_ROULETTE_CONSTANTS.NUTS_PER_BULLET);
      let requiredWegld = new BigNumber(String(BigInt(nbOfBullets) * rewards.wegldPerBullet));

      if (nbOfBullets === 6) {
        requiredWegld = requiredWegld.minus(String(NUTS_ROULETTE_CONSTANTS.WEGLD_PER_BULLET / BigInt(2)));
      }

      if (goNutsEgldPerBullet) {
        requiredWegld = requiredWegld.times(NUTS_ROULETTE_CONSTANTS.GO_NUTS_WEGLD_WITH_DISCOUNT);

        requiredWegld = requiredWegld
          .plus(
            goNutsEgldPerBullet
              .multipliedBy(1.01) // 1% slippage
              .multipliedBy(nbOfBullets)
          )
          .decimalPlaces(0);
      }

      if (requiredWegld.gt(wegldBalance)) {
        const toWrap = requiredWegld.minus(wegldBalance);

        transactions.push(wegldSwapContract.wrapEgld(accountElrond.address.bech32(), toWrap.toString()));
      }

      let nft: TokenTransfer = null;
      if (jackpotType === 1) {
        nft = TokenTransfer.nonFungible(BLOCKCHAIN.NFT_TOKEN_IDENTIFIER, selectedNftNonce);
      } else if (jackpotType === 2) {
        nft = TokenTransfer.nonFungible(BLOCKCHAIN.NEXT_GEN_NFT_TOKEN_IDENTIFIER, selectedNftNonce);
      }

      transactions.push(
        await gamesProxyContract.nutsRoulette(
          accountElrond,
          nbOfBullets,
          requiredNuts,
          requiredWegld,
          nft,
          getters[REFERRAL_GETTERS.REFERER],
          !!goNutsEgldPerBullet
        )
      );

      return await elrondHelper.sendTransactions(transactions, accountElrond);
    },
    async [NUTS_ROULETTE_ACTIONS.GET_REWARDS]({ commit }) {
      let [
        nutsJackpot,
        specialChance,
        wegldJackpot,
        nutsNfts,
        nextGenNfts,
        extraSpecialNormalAll,
        extraSpecialNextGenAll,
        extraSpecialSuperAll,
      ] = await Promise.all([
        nutsRouletteContract.getActualNutsJackpot(),
        nutsRouletteContract.getSpecialChance(),
        nutsRouletteContract.getWegldJackpot(),
        nutsRouletteContract.getNutsNfts(),
        nutsRouletteContract.getNextGenNfts(),
        Promise.resolve([]),
        Promise.resolve([]),
        Promise.resolve([]),
        // nutsRouletteContract.getExtraSpecialJackpots(3), // TODO: Re-enable special jackpots, but probably only in microservice
        // nutsRouletteContract.getExtraSpecialJackpots(1),
        // nutsRouletteContract.getExtraSpecialJackpots(2),
      ]);

      specialChance = specialChance ? specialChance / 100 : 10;

      const normalNft = nutsNfts.length ? await elrondApiHelper.getNft(nutsNfts[0]) : null;
      const nextGenNft = nextGenNfts.length
        ? await elrondApiHelper.getNft(nextGenNfts[0], BLOCKCHAIN.NEXT_GEN_NFT_TOKEN_IDENTIFIER)
        : null;

      const jackpot = nutsJackpot[0];
      const wegldPerBullet = jackpot.lt(new BigNumber(NUTS_ROULETTE_CONSTANTS.NUTS_PER_BULLET * 6))
        ? NUTS_ROULETTE_CONSTANTS.WEGLD_PER_BULLET / 2n
        : NUTS_ROULETTE_CONSTANTS.WEGLD_PER_BULLET;

      let extraSpecialNormal: TokenTransfer | NFT_POST | undefined = extraSpecialNormalAll.pop();
      let extraSpecialNextGen: TokenTransfer | NFT_POST | undefined = extraSpecialNextGenAll.pop();
      let extraSpecialSuper: TokenTransfer | NFT_POST | undefined = extraSpecialSuperAll.pop();

      if (extraSpecialNormal && extraSpecialNormal.nonce !== 0) {
        extraSpecialNormal = await elrondApiHelper.getNft(
          extraSpecialNormal.nonce,
          (extraSpecialNormal as TokenTransfer).tokenIdentifier
        );
      }
      if (extraSpecialNextGen && extraSpecialNextGen.nonce !== 0) {
        extraSpecialNextGen = await elrondApiHelper.getNft(
          extraSpecialNextGen.nonce,
          (extraSpecialNextGen as TokenTransfer).tokenIdentifier
        );
      }
      if (extraSpecialSuper && extraSpecialSuper.nonce !== 0) {
        extraSpecialSuper = await elrondApiHelper.getNft(
          extraSpecialSuper.nonce,
          (extraSpecialSuper as TokenTransfer).tokenIdentifier
        );
      }

      const rewards: REWARD = {
        jackpot,
        nextGenJackpot: nutsJackpot[1],
        superJackpot: nutsJackpot[2],
        superJackpotWegld: wegldJackpot,
        specialChance: specialChance,
        nutsNft: normalNft,
        nextGenNft,
        wegldPerBullet,
        extraSpecialNormal,
        extraSpecialNextGen,
        extraSpecialSuper,
      };

      commit(NUTS_ROULETTE_MUTATIONS.REWARDS, rewards);
    },
  },
};
